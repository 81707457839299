
import client from "../core/axios/client/guest.client";
import { loginClient } from "../core/axios/client/logged-in";

class ProductService {
    static productServiceInstance: ProductService;

    private constructor() { };

    public static getInstance() {
        if (!ProductService.productServiceInstance) {
            ProductService.productServiceInstance = new ProductService();
        }
        return ProductService.productServiceInstance;
    }

    async lastedProduct() {
        try {
            const response = await client.get('/products/lastedproduct');
            return response.data;
        } catch (error) {
            throw error;
        }
    }

    async allProduct() {
        try {
            const response = await client.get('/products/allproduct');
            return response.data;
        } catch (error) {
            throw error;
        }
    }

    async searchProduct(productName: string) {
        try {
            const response = await client.get(`/products/search/${productName}`);
            return response.data;
        } catch (error) {
            throw error;
        }
    }

    async showProduct(productId: string) {
        try {
            const response = await client.get(`/products/show/${productId}`);
            return response.data;
        } catch (error) {
            throw error;
        }
    }

    async getProductBrand(brandId: string) {
        try {
            const response = await client.get(`/products/brands/product/${brandId}`);
            return response.data;
        } catch (error) {
            throw error;
        }
    }

    async getProductCategory(categoryId: string) {
        try {
            const response = await client.get(`/products/categories/product/${categoryId}`);
            return response.data;
        } catch (error) {
            throw error;
        }
    }

    async getBrands() {
        try {
            const response = await client.get('/products/brands');
            return response.data;
        } catch (error) {
            throw error;
        }
    }

    async getCategories() {
        try {
            const response = await client.get('/products/categories');
            return response.data;
        } catch (error) {
            throw error;
        }
    }

    //Card

    async addCard(product: any) {
        try {
            const response = await loginClient.post('/cards/add-to-card', {
                product
            })
            return response.data;
        } catch (error) {
            throw error
        }
    }

    async getCard() {
        try {
            const response = await loginClient.get('/cards/get-card')
            return response.data;
        } catch (error) {
            throw error
        }
    }

    async actionsCard(productId: number, action: string) {
        try {
            const response = await loginClient.post('/cards/actions-card', {
                productId,
                action
            })
            return response.data;
        } catch (error) {
            throw error
        }
    }

    //Order

    async createOrder() {
        try {
            const response = await loginClient.post('/orders/create-order')
            return response.data;
        } catch (error) {
            throw error
        }
    }

    async getOrderDetails(orderRef: string) {
        try {
            const response = await loginClient.get(`/orders/get-order-details/${orderRef}`)
            return response.data;
        } catch (error) {
            throw error
        }
    }

    async verifyOrder(orderRef: string, email_adress: string) {
        try {
            const response = await client.post(`/products/verify-order`, {
                email_adress,
                order_ref: orderRef
            })
            return response.data;
        } catch (error) {
            throw error
        }
    }

}


export default ProductService.getInstance();