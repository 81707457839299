import { Brands } from './brands.component';
import { Category } from './catgory.component';

export const LeftDiv = () => {


    return (
        <div className="col-sm-3">
            <div className="left-sidebar">
                <Category />
                <Brands />
                <div style={{ marginTop: 10 }}>
                    <img src={require("../assets/images/mee/mee-eau.jpg")} style={{ width: '100%', height: '100%' }} alt="" />
                </div>
                <div style={{ marginTop: 10, marginBottom: 10 }}>
                    <img src={require("../assets/images/mee/mee-alimentation-generale-livraison.jpg")} style={{ width: '100%', height: '100%' }} alt="" />
                </div>

                <div style={{ marginTop: 10, marginBottom: 10 }}>
                    <img src={require("../assets/images/mee/mee-1.jpg")} style={{ width: '100%', height: '100%' }} alt="" />
                </div>

            </div>
        </div>
    )
}