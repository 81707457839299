import { useEffect } from 'react'

import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setIsLoading, setNotification } from '../core/store/slices/loadingSlice';
import authService from '../services/auth.service';

function VerifyEmail() {
  const { token } = useParams()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  useEffect(() => {
    document.title = 'Email verification';
    dispatch(setIsLoading(true))
    document.body.style.overflow = 'hidden';
    // console.warn(token)
    authService.tokenVerify(token as string)
      .then(response => {
        dispatch(setIsLoading(false))
        if (response.valid === true) {
          dispatch(setNotification({ type: 'success', message: 'Adresse email validée avec succes', visible: true }))
          navigate('/connexion')
        } else if (response.already === true) {
          dispatch(setNotification({ type: 'info', message: 'Adresse email déjà validée', visible: true }))
          navigate('/')
        } else {
          dispatch(setNotification({ type: 'error', message: 'Une erreur s\'est produite', visible: true }))
          navigate('/')
        }
      })
      .catch(err => {
        dispatch(setNotification({ type: 'error', message: 'Une erreur s\'est produite', visible: true }))
        navigate('/')
      })
  }, [navigate, dispatch, token]);
  return (
    <>
    </>
  )
}

export default VerifyEmail

