
import client from "../core/axios/client/guest.client";

class ContactService {
    static contactServiceInstance: ContactService;

    private constructor() { };

    public static getInstance() {
        if (!ContactService.contactServiceInstance) {
            ContactService.contactServiceInstance = new ContactService();
        }
        return ContactService.contactServiceInstance;
    }

    async addToNewsletter(email_address: string) {
        try {
            const response = await client.post('/contact/add-to-newsletter', {
                email_address
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    }

    async createContactMessage(guest_name: string, email_address: string, contact_object: string, contact_message: string) {
        try {
            const response = await client.post('/contact/create-contact', {
                guest_name, email_address, contact_object, contact_message
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    }

}


export default ContactService.getInstance();