import { useEffect, useState } from 'react'

import Header from '../components/header';
import Footer from '../components/footer';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setIsLoading, setNotification } from '../core/store/slices/loadingSlice';
import authService from '../services/auth.service';

interface IPassword {
  password: string;
  confirmPassword: string
}

function PasswordReset() {
  const { token } = useParams()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [password, setPassword] = useState<IPassword>({ password: '', confirmPassword: '' })

  const resetPassword = async () => {
    dispatch(setIsLoading(true))
    document.body.style.overflow = 'hidden';
    if (!(password.password && password.confirmPassword)) {
      setTimeout(() => {
        dispatch(setIsLoading(false))
        document.body.style.overflow = 'visible';
        dispatch(setNotification({ type: 'error', message: 'Vos champs sont vides', visible: true }))
      }, 3000)
      return
    }
    if (password.password !== password.confirmPassword) {
      setTimeout(() => {
        dispatch(setIsLoading(false))
        document.body.style.overflow = 'visible';
        dispatch(setNotification({ type: 'error', message: 'Vos mots mot de passe ne correspondent pas', visible: true }))
      }, 3000)
    }
    else {
      authService.changedPassword(token as string, password.password).then(
        (response) => {
          if (response.changed) {
            setTimeout(() => {
              dispatch(setIsLoading(false))
              document.body.style.overflow = 'visiible';
              dispatch(setNotification({ type: 'success', message: 'Mot de passe changé avec succès', visible: true }))
              navigate('/connexion');
            }, 5000)
          }
          else {
            setTimeout(() => {
              dispatch(setIsLoading(false))
              document.body.style.overflow = 'visible';
              dispatch(setNotification({ type: 'error', message: 'Quelques chose s\'est mal passé', visible: true }))
            }, 5000)
          }
        }
      ).catch((err) => {
        setTimeout(() => {
          dispatch(setIsLoading(false))
          document.body.style.overflow = 'visible';
          dispatch(setNotification({ type: 'error', message: 'BAD REQUEST', visible: true }))
          navigate('/');
        }, 3000)
      })
    }

  }

  useEffect(() => {
    document.title = 'Changement de mot de passe';
    // dispatch(setIsLoading(true))
    document.body.style.overflow = 'hidden';
    // console.warn(token)
    authService.tokenVerify(token as string)
      .then(response => {
        // dispatch(setIsLoading(false))
        if (response.valid === false) {
          dispatch(setNotification({ type: 'error', message: 'Une erreur s\'est produite', visible: true }))
          navigate('/')
        }
      })
      .catch(err => {
        // dispatch(setIsLoading(false))
        dispatch(setNotification({ type: 'error', message: 'Une erreur s\'est produite', visible: true }))
        navigate('/')
      })
  }, [navigate, dispatch, token]);
  return (
    <>
      <Header />
      <div className="container" style={{ paddingBottom: 60 }}>
        <div className="row">
          <div className="col-sm-4 col-sm-offset-1">
            <div className="login-form">
              <h2>Changer le mot de passe</h2>
              <form action="#">
                <h5>Mot de passe</h5>
                <input type="password" placeholder="Mot de passe" value={password.password} required onChange={(value) => { setPassword({ ...password, password: value.target.value }) }} />
                <h5>Confirmer le mot de passe</h5>
                <input type="password" placeholder="Confirmer le mot de passe" required value={password.confirmPassword} onChange={(value) => { setPassword({ ...password, confirmPassword: value.target.value }) }} />
                <span style={{ display: 'flex', justifyContent: 'space-between', paddingTop: '10px' }}>
                </span>
                <button type="submit" className="btn btn-default" onClick={resetPassword}>Changer mon mot de passe</button>
              </form>
            </div>{/*/login form*/}
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default PasswordReset

