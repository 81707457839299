import { useRouteError } from "react-router-dom";
import Header from "../components/header";
import Footer from "../components/footer";

export default function ErrorPage() {
  const error: any = useRouteError();
  console.error(error);
  const error_404 = require('../assets/images/404/404.png')
  return (
    <>
      <Header />
      <div className="container text-center">
        <div className="content-404">
          <img src={error_404} className="img-responsive" alt="" />
          <h1><b>OPPS!</b> Page non trouvé</h1>
          <p>Euh... Donc on dirait que vous avez broyé quelque chose. La page que vous recherchez est apparue et a disparu.</p>
          <h2><a href="/">Retourner à la page home</a></h2>
        </div>
      </div>
      <br />
      <Footer />
    </>

  );
}