// PaymentModal.tsx

import React from 'react';

import Modal from 'react-modal';
import './styles/payment.method.css'

interface PaymentModalProps {
    action: string,
    email: string,
    isOpen: boolean;
    onRequestClose: () => void;
    handleOnChange: (value: string) => void;
    handleOnClick: () => void;
}



const EmailModal: React.FC<PaymentModalProps> = ({ action, email, isOpen, onRequestClose, handleOnChange, handleOnClick }) => {

    return (

        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            style={{
                content: {
                    top: '50%',
                    left: '50%',
                    right: 'auto',
                    bottom: 'auto',
                    marginRight: '-50%',
                    transform: 'translate(-50%, -50%)',
                    padding: '20px',
                    borderRadius: '8px',
                    boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
                    maxWidth: '400px',
                    width: '80%',
                    height: 'auto',
                    textAlign: 'center',
                }
            }}
            contentLabel="Example Modal"
        >
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '50px' }}>
                <h3 style={{ flex: '1' }}>Votre adresse email</h3>
                <button onClick={onRequestClose} style={{ flex: '0', background: 'none', border: 'none', cursor: 'pointer' }}>
                    &#10006;
                </button>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
                <input
                    type="email"
                    value={email}
                    placeholder="Entrez votre adresse email"
                    style={{ width: '100%', padding: '10px' }}
                    onChange={(value) => { handleOnChange(value.target.value) }}
                />
            </div>
            <button style={{ padding: '7px 50px', background: '#007bff', color: '#fff', border: 'none', marginTop: '30px' }} onClick={handleOnClick}>
                Envoyer
            </button>
        </Modal>

    );
};

export default EmailModal;
