import { JSXElementConstructor, Key, ReactElement, ReactFragment, ReactPortal, useEffect, useState } from 'react'
import ProductCard from '../components/customCard';
import Header from '../components/header';
import Footer from '../components/footer';
import Pagination from 'react-bootstrap/Pagination';
import { LeftDiv } from '../components/LeftDiv.component';
import productsService from '../services/products.service';
import { setIsLoading, setNotification } from '../core/store/slices/loadingSlice';
import { useDispatch } from 'react-redux';
import CustomCard from '../components/customCard';
import { environement } from '../environements/environement';
import { useNavigate } from 'react-router';

function Shop() {
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const [data, setData] = useState([])
  useEffect(() => {
    dispatch(setIsLoading(true))
    document.title = 'Mee Market';
    document.body.style.overflow = 'hidden';
    productsService.allProduct().then(response => {
      // console.warn(response)
      setData(response)
      setTimeout(() => {
        dispatch(setIsLoading(false))
        document.body.style.overflow = 'visible';
      }, 500)
    }).catch(err => {
      // console.warn(err)
      dispatch(setNotification({ type: 'error', message: 'BAD REQUEST', visible: true }))
      dispatch(setIsLoading(false))
      document.body.style.overflow = 'visible';
    })
  }, [dispatch]);


  const [currentPage, setCurrentPage] = useState(1)
  const articlesPerPage = environement.shop_product_per_page; // Nombre d'articles par page
  const lastIndex = currentPage * articlesPerPage;
  const firstIndex = lastIndex - articlesPerPage;
  const maxPageNumbersToShow = 4;


  const displayedArticles = data.slice(firstIndex, lastIndex);
  const npage = Math.ceil(data.length / articlesPerPage)
  // const numbers = [Array(npage + 1).keys()].slice(1)
  const numbers = Array.from({ length: npage }, (_, i) => i + 1);
  console.log(numbers);


  const pageNumbers = [];
  for (let i = Math.max(1, currentPage - maxPageNumbersToShow); i <= Math.min(npage, currentPage + maxPageNumbersToShow); i++) {
    pageNumbers.push(i);
  }

  const showEllipsisStart = currentPage - maxPageNumbersToShow > 2;
  const showEllipsisEnd = currentPage + maxPageNumbersToShow < npage - 1;

  useEffect(() => {
  
    const queryParams = new URLSearchParams(window.location.search);
    const pageQueryParam = queryParams.get('page');

    console.log('Page from URL:', pageQueryParam);
    if (pageQueryParam) {
      setCurrentPage(parseInt(pageQueryParam));
    }
  }, [setCurrentPage]);

 


  useEffect(() => {
    document.title = 'Shop';
  }, []);

  const nextPage = () => {
    if (currentPage < npage) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  useEffect(() => {
    console.log('Current page:', currentPage);
    navigate(`/shop?page=${currentPage}`);
  }, [currentPage, navigate]);

  return (
    <div>
      <Header />
      <section id="advertisement">
        <div className="container">
          <img src={require('../assets/images/shop/banner.jpg')} alt="" />
        </div>
      </section>
      <section>
        <div className="container">
          <div className="row">
            <LeftDiv />
            <div className="col-sm-9 padding-right">
              <div className="features_items">{/*features_items*/}
                <h2 className="title text-center">NOS PRODUITS</h2>
                {
                  displayedArticles.map((value, i) =>
                    <CustomCard data={value} key={i} />
                  )}
                {/* <div className="col-sm-4">
                  <div className="product-image-wrapper">
                    <div className="single-products">
                      <div className="productinfo text-center">
                        <img src={require('../assets/images/shop/product12.jpg')} alt="" />
                        <h2>$56</h2>
                        <p>Easy Polo Black Edition</p>
                        <a href="" className="btn btn-default add-to-cart"><i className="fa fa-shopping-cart" />Add to cart</a>
                      </div>
                      <div className="product-overlay">
                        <div className="overlay-content">
                          <h2>$56</h2>
                          <p>Easy Polo Black Edition</p>
                          <a href="" className="btn btn-default add-to-cart"><i className="fa fa-shopping-cart" />Add to cart</a>
                        </div>
                      </div>
                    </div>
                    <div className="choose">
                      <ul className="nav nav-pills nav-justified">
                        <li><a href=""><i className="fa fa-plus-square" />Add to wishlist</a></li>
                        <li><a href=""><i className="fa fa-plus-square" />Add to compare</a></li>
                      </ul>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <div className="text-center">
        <ul className="pagination">
          <li className={currentPage === 1 ? 'disabled' : ''}>
            <a href="" onClick={prevPage}>Pre</a>
          </li>
          {numbers.map((number) => (
            <li
              className={currentPage === number ? 'active' : ''}
              key={number}
            >
              <a href="" onClick={() => setCurrentPage(number)}>
                {number}
              </a>
            </li>
          ))}
          <li className={currentPage === npage ? 'disabled' : ''}>
            <a href="" onClick={nextPage}>Suiv</a>
          </li>
        </ul>
      </div> */}
      <div className="text-center">
        <ul className="pagination">
          <li className={currentPage === 1 ? 'disabled' : ''}>
            <a href="#" onClick={prevPage}>Pre</a>
          </li>

          {showEllipsisStart && <li><span>...</span></li>}

          {pageNumbers.map((number) => (
            <li
              className={currentPage === number ? 'active' : ''}
              key={number}
            >
              <a href="#" onClick={() => setCurrentPage(number)}>
                {number}
              </a>
            </li>
          ))}

          {showEllipsisEnd && <li><span>...</span></li>}

          <li className={currentPage === npage ? 'disabled' : ''}>
            <a href="#" onClick={nextPage}>Suiv</a>
          </li>
        </ul>
      </div>
      <Footer />
    </div>

  )
}


export default Shop



