
import client from "../core/axios/client/guest.client";
import { ICustomerRegister } from "./interfaces/user.interface";
import localStorageService from "./localStorage.service";

class AuthService {
    static authServiceInstance: AuthService;

    private constructor() { };

    public static getInstance() {
        if (!AuthService.authServiceInstance) {
            AuthService.authServiceInstance = new AuthService();
        }
        return AuthService.authServiceInstance;
    }

    async login(email: string, password: string) {
        try {
            const response = await client.post('/auth/login', {
                customer_email: email,
                customer_password: password
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    }

    async logout() {
        try {
            localStorageService.remove('MeeMarketCustomerAccessToken');
            localStorageService.remove('MeeMarketCustomerRefreshToken');
            localStorageService.remove('MeeMarketCustomerAccessTokenExpire');
            return true
        } catch (error) {
            console.warn(error)
            // dispatch(setNotification({ type: 'success', message: 'Produit supprimé avec succès', visible: true }))
        }

    }


    async register(customer: ICustomerRegister) {
        try {
            const response = await client.post('/auth/signup', customer);
            return response.data;
        } catch (error) {
            throw error;
        }
    }


    async tokenVerify(token: string) {
        try {
            const response = await client.get(`/auth/token-verify/${token}`);
            return response.data;
        } catch (error) {
            throw error;
        }
    }

    async resentVerifyMail(customer_email: string) {
        try {
            const response = await client.post('/auth/resent-verify', {
                customer_email
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    }

    async sendResetMail(customer_email: string) {
        try {
            const response = await client.post('/auth/send-forgot-mail', {
                customer_email
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    }
    async changedPassword(token: string, customer_password: string) {
        try {
            const response = await client.post(`/auth/reset-password/${token}`, {
                customer_password
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    }

}


export default AuthService.getInstance();