import { useEffect, useState } from 'react'
import parsePhoneNumber from 'libphonenumber-js';
import PhoneInput from 'react-phone-input-2'
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import 'react-phone-input-2/lib/style.css'

import Header from '../components/header';
import Footer from '../components/footer';
import { useNavigate, useParams } from 'react-router-dom';
import PaymentModal from './components/payment.method.component';
import productsService from '../services/products.service';
import { IOrder } from '../services/types/product.types';
import { useDispatch, useSelector } from 'react-redux';
import { selectCustomerDetails, selectModePaiement } from '../core/store/slices/userSlice';
import { IAdress } from '../services/types/user.types';
import userServices from '../services/user.services';
import { setIsLoading, setNotification } from '../core/store/slices/loadingSlice';
import paymentServices from '../services/payment.services';
import { isConnectedChecked } from '../functions/connected.check';
import { selectisconnected, setIsConnected } from '../core/store/slices/authSlice';
import orderServices from '../services/order.services';


interface IPaymentDeclaration {
  paymentMethod?: string,
  paymentNumber?: string,
  payemntReference?: string,
  paymentDate?: string,
  paymentComment?: string
}

export const Order = () => {
  const { orderRef } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const isConnected = useSelector(selectisconnected)
  const modeDePaiement = useSelector(selectModePaiement)
  const [deliveryFees, setDeliveryFees] = useState(0)
  const [isValid, setIsValid] = useState(false);
  const [visible, setVisible] = useState(false);
  const [kilometer, setKilometer] = useState(0)
  const [phoneNumber, setPhoneNumber] = useState('');
  const [address, setAddress] = useState<IAdress>()
  const customerInfo = useSelector(selectCustomerDetails)
  const [orderDetails, setOrderDetails] = useState<IOrder[]>([])
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedPayment, setSelectedPayment] = useState('');
  const prixTotalTTC = orderDetails.reduce((accumulateur, objetCourant) => accumulateur + (objetCourant.product.price * objetCourant.product.qte + ((objetCourant.product.price * objetCourant.product.qte) * (objetCourant.product.taxe / 100))), 0);
  const prixTotalHT = orderDetails.reduce((accumulateur, objetCourant) => accumulateur + (objetCourant.product.price * objetCourant.product.qte), 0);
  const taxes = orderDetails.reduce((accumulateur, objetCourant) => accumulateur + ((objetCourant.product.price * objetCourant.product.qte) * (objetCourant.product.taxe / 100)), 0);
  const [paymentDetails, setpaymentDetails] = useState<IPaymentDeclaration>({
    paymentMethod: 'Tmoney',
    payemntReference: '',
    paymentComment: '',
    paymentDate: '',
    paymentNumber: ''
  })

  isConnectedChecked(dispatch)
    .then(response => {
      if (response === false) {
        navigate('/connexion')
      }
    })


  const openModal = () => {
    setModalOpen(true);
  }

  const closeModal = () => {
    setModalOpen(false);
  }

  const handleSelectPayment = (paymentMethod: string) => {
    setSelectedPayment(paymentMethod);
  }
  const handleOnChange = (value: string, country: any) => {
    setPhoneNumber(value)
    const phoneNumber = parsePhoneNumber(`+${value}`, country);
    const isValidNumber = /^(22890|22891|22892|22893|22870|22899|22898|22897|22896)\d{6}$/.test(value);
    setIsValid(phoneNumber && isValidNumber ? phoneNumber.isValid() : false);
  };

  const declarePayment = () => {
    dispatch(setIsLoading(true))
    if (paymentDetails?.paymentMethod === 'Cash') {
      setTimeout(() => {
        dispatch(setIsLoading(false))
        dispatch(setNotification({ type: 'info', message: 'Vous effectuerez le payement à la livraison', visible: true }))
        navigate('/orders-list');
      }, 2000)
    }
    else {
      if (paymentDetails.payemntReference?.length === 0 || paymentDetails.paymentDate?.length === 0 || paymentDetails.paymentNumber?.length === 0) {
        dispatch(setIsLoading(false))
        dispatch(setNotification({ type: 'error', message: 'Remplissez tous les champs de déclaration de paiements. Merci', visible: true }))
      } else {
        dispatch(setIsLoading(false))
        // console.warn(paymentDetails)
        paymentServices.declarePayment(paymentDetails.paymentNumber as string, prixTotalTTC, paymentDetails.paymentMethod as string, orderRef as string, paymentDetails.payemntReference as string, paymentDetails.paymentDate as string, paymentDetails.paymentComment as string)
          .then(response => {
            navigate('/orders-list');
            dispatch(setIsLoading(false))
            dispatch(setNotification({ type: 'success', message: 'Paiement enregistrée avec succès, En cours de validation', visible: true }))
          })
          .catch(err => {
            dispatch(setIsLoading(false))
            dispatch(setNotification({ type: 'error', message: 'Une erreur s\'est produite', visible: true }))
          })
      }
    }
  }

  const orderCommandWithCash = () => {
    dispatch(setIsLoading(true))
    setTimeout(() => {
      dispatch(setIsLoading(false))
      dispatch(setNotification({ type: 'info', message: 'Payement à la livraison', visible: true }))
      navigate('/orders-list');
    }, 2000)
  }
  const processpayment = async () => {
    // if (modeDePaiement === 'Tmoney' || modeDePaiement === 'Flooz') {
    setVisible(true)
    dispatch(setIsLoading(true))
    paymentServices.paygatePayment(phoneNumber, prixTotalTTC, modeDePaiement, orderRef as string)
      .then(response => {
        setTimeout(() => {
          dispatch(setIsLoading(false))
          navigate('/orders-list');
        }, 2000)
      })
      .catch(err => {
        // console.warn(err)
      })
    // }
  }
  useEffect(() => {
    document.title = 'Order';
    orderServices.getOrderPaymentStatus(orderRef as string)
      .then(response => {
        // console.warn(response)
        if (response.paymentStatus === true) {
          dispatch(setNotification({ type: 'error', message: 'Quelque chose s\'est mal passé. Merci de réesayer', visible: true }))
          navigate('/orders-list')
        }
      })
      .catch(err => {
        dispatch(setNotification({ type: 'error', message: 'Quelque chose s\'est mal passé. Merci de réesayer', visible: true }))
        navigate('/orders-list')
      })
    productsService.getOrderDetails(orderRef as string)
      .then(response => {
        // console.warn(response)
        setOrderDetails(response.ordersLsits)
        setDeliveryFees(response.orderDeliveryFees)
      })
      .catch(err => {
        // console.warn(err)
        dispatch(setNotification({ type: 'error', message: 'Quelque chose s\'est mal passé. Merci de réesayer', visible: true }))
        navigate('/orders-list')
      })
    userServices.getDefaultAdress()
      .then(response => {
        setAddress(response)
      })
      .catch(err => {
        dispatch(setNotification({ type: 'info', message: 'Pas d\'adresse enrégistrée', visible: true }))
      })
  }, [orderRef, dispatch]);



  return (
    <div>
      <Header />
      <ConfirmDialog visible={visible} icon={'pi pi-exclamation-triangle'} header={'Payement'} message={'Vous allez recevoir une notification de paiement sur votre télephones'} acceptLabel='Continuer' accept={() => navigate('/orders-list')} rejectLabel='Fermer' />
      <PaymentModal
        isOpen={modalOpen}
        onRequestClose={closeModal}
        onSelectPayment={handleSelectPayment}
      />
      <section id="cart_items">
        <div className="container">
          <div className="breadcrumbs">
            <ol className="breadcrumb">
              <li><a href="">Accueil</a></li>
              <li className="active">Commande</li>
            </ol>
          </div>{/*/breadcrums*/}
          <div className="step-one">
            <h2 className="heading">Récapitulatif de la commande</h2>
          </div>
          <div className="checkout-options">
            <h3>Réference de la commande</h3>
            <p>{orderRef}</p>
            <ul className="nav">
              <li>
                <label> Temps d'estimation de la livraison:</label>
              </li>
              <li>
                <a>24 Heures</a>
              </li>
            </ul>
          </div>{/*/checkout-options*/}
          <div className="register-req">
            <p>Vérifier vos informations personnelles et votre adresse de livraison</p>
          </div>{/*/register-req*/}
          <div className="shopper-informations">
            <div className="row">
              <div className="col-sm-3">
                <div className="shopper-info">
                  <p>Vos informations</p>
                  <form>
                    <input type="text" placeholder="Display Name" value={customerInfo.firstName} disabled />
                    <input type="text" placeholder="Display Name" value={customerInfo.lastName} disabled />
                    <input type="text" placeholder="User Name" value={customerInfo.email} disabled />
                  </form>
                </div>
              </div>
              <div className="col-sm-5 clearfix">
                <div className="bill-to">
                  <p>Information de livraison</p>
                  <div className="form-one">
                    <form>
                      <input type="text" placeholder="Email*" value={customerInfo.email} disabled />
                      <input type="text" placeholder="Address 1 *" value={address?.adresseName} disabled />
                      <input type="text" placeholder="Mobile Phone" value={address?.phoneNumber} disabled />
                    </form>
                  </div>
                  <div className="form-two">
                    <form>
                      <input type="text" placeholder="Zip / Postal Code *" value={address?.postalBox} disabled />
                      <select>
                        <option>{address?.city}</option>
                      </select>
                      <input type="text" placeholder="Street" value={address?.street} disabled />
                    </form>
                  </div>
                </div>
              </div>
              {/* <div className="col-sm-4">
                <div className="order-message">
                  <p>Shipping Order</p>
                  <textarea name="message" placeholder="Notes about your order, Special Notes for Delivery" rows={16} defaultValue={""} />
                  <label><input type="checkbox" /> Shipping to bill address</label>
                </div>
              </div> */}
            </div>
          </div>
          <div className="review-payment">
            <h2>Récapitulatif des articles</h2>
          </div>
          <div className="table-responsive cart_info">
            <table className="table table-condensed">
              <thead>
                <tr className="cart_menu">
                  <td className="description">Produit</td>
                  <td className="price">Prix HT</td>
                  <td className="total">Taxes</td>
                  <td className="quantity">Quantités</td>
                  <td className="total">Total TTC</td>
                </tr>
              </thead>
              <tbody>
                {
                  orderDetails.map((value, index) =>
                    <tr>
                      <td className="cart_description">
                        <h5><a href="#">{value.product.productName}</a></h5>
                        {value.product.color && (value.product.color !== '#fff') &&
                          <div style={{ display: 'flex' }}>
                            <p>Couleur: </p>
                            <p style={{ marginTop: 2, marginLeft: 5, backgroundColor: value.product.color, borderRadius: 50, width: '15px', height: '15px' }}></p>
                          </div>
                        }
                      </td>
                      <td className="cart_price">
                        <p>{value.product.price.toLocaleString('fr-FR', { style: 'decimal' })} XOF</p>
                      </td>
                      <td className="cart_price">
                        <p>{value.product.taxe} %</p>
                      </td>
                      <td className="cart_quantity">
                        <div className="cart_quantity_button">
                          <h4>{value.product.qte}</h4>
                        </div>
                      </td>
                      <td className="cart_total">
                        <p className="cart_total_price">{((value.product.price * value.product.qte) + ((value.product.price * value.product.qte) * (value.product.taxe / 100))).toLocaleString('fr-FR', { style: 'decimal' })} XOF</p>
                      </td>
                    </tr>
                  )
                }
                <tr>
                  <td colSpan={3}>&nbsp;</td>
                  <td colSpan={2}>
                    <table className="table table-condensed total-result">
                      <tbody><tr>
                        <td>Prix total HT</td>
                        <td>{prixTotalHT.toLocaleString('fr-FR', { style: 'decimal' })} XOF</td>
                      </tr>
                        <tr>
                          <td>Taxe</td>
                          <td>{taxes.toLocaleString('fr-FR', { style: 'decimal' })} XOF</td>
                        </tr>
                        <tr className="shipping-cost">
                          <td>Frais de livraison</td>
                          <td>{deliveryFees ? deliveryFees.toLocaleString('fr-FR', { style: 'decimal' }) + ' XOF' : 'Error, elle vous sera communiqué a la livraison'}</td>
                        </tr>
                        <tr>
                          <td>Total TTC</td>
                          <td><span>{Number(prixTotalTTC + (deliveryFees ? deliveryFees : 0)).toLocaleString('fr-FR', { style: 'decimal' })} XOF</span></td>
                        </tr>
                      </tbody></table>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div>
            <div className="register-req">
              <p>Numero de transfert & Information du paiement</p>
            </div>{/*/register-req*/}
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <img src={require('../pages/components/assets/img/flooz.png')} style={{ width: '6%', height: '6%' }} alt="" />
              <h4 style={{ color: 'gray', marginLeft: 10 }}>Numéro Marchant: 79322108</h4>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <img src={require('../pages/components/assets/img/tmoney.png')} style={{ width: '6%', height: '6%' }} alt="" />
              <h4 style={{ color: 'gray', marginLeft: 10 }}>Numéro Marchant: 1000015</h4>
              <h4 style={{ color: 'black', marginLeft: 50 }}>Ex: *145*5*Montant*1000015# Nom: ASIAN INDUSTRY 2</h4>
            </div>
            {/* <div style={{ display: 'flex', flexDirection: 'row' }}>
              <img src={require('../pages/components/assets/img/ecobank.png')} style={{ width: '6%', height: '6%' }} alt="" />
              <div>
                <h4 style={{ color: 'gray', marginLeft: 10 }}> Titulaire: LADEH AHLIDZA AKOUVI</h4>
                <h4 style={{ color: 'gray', marginLeft: 10 }}> Code Banque: TG055</h4>
                <h4 style={{ color: 'gray', marginLeft: 10 }}> Code Agence: 01701</h4>
                <h4 style={{ color: 'gray', marginLeft: 10 }}> Code Swift: ECOCTGTG</h4>
                <h4 style={{ color: 'gray', marginLeft: 10 }}> Numéro de compte: 141388948001</h4>
                <h4 style={{ color: 'gray', marginLeft: 10 }}> Code pays: TG53</h4>
                <h4 style={{ color: 'gray', marginLeft: 10 }}> Clé RIB: 51</h4>
                <h4 style={{ color: 'gray', marginLeft: 10 }}> Code IBAN: TG53TG0550170114138894800151</h4>
              </div>
            </div> */}
          </div>
          {
            (modeDePaiement === 'Tmoney' || modeDePaiement === 'Flooz') &&
            <>
              <div className="register-req">
                <p>Entrez votre numéro {modeDePaiement.toUpperCase()} (Format: +228 9XXXXXXX)</p>
              </div>
              < PhoneInput
                country={'tg'}
                onlyCountries={['tg']}
                placeholder='+228 9XXXXXXX'
                value={phoneNumber}
                onChange={handleOnChange}
              />
            </>

          }
          {/* <div className="payment-options">
            <a className="btn btn-primary" style={{ marginLeft: '-10px', marginBottom: '25px' }} href="#" onClick={openModal}>Choix du mode de paiement</a>
            {(isValid && modeDePaiement !== 'cash') &&
              <a className="btn btn-primary"  style={{marginBottom: '25px' }} href="#" onClick={processpayment}>Payer</a>
            }
            {
              modeDePaiement === 'Cash' &&
              <a className="btn btn-primary" style={{marginBottom: '25px' }} href="#" onClick={orderCommandWithCash}>Continuer</a>
            }
          </div> */}

          <div className="payment-options">
            <div className="shopper-informations">
              <div className="row">
                <div className="col-sm-3">
                  <div className="shopper-info">
                    <p>Mode de paiement</p>
                    <form>
                      <select value={paymentDetails.paymentMethod} onChange={(value) => { setpaymentDetails({ ...paymentDetails, paymentMethod: value.target.value }) }}>
                        <option value={'Tmoney'}>Tmoney</option>
                        <option value={'Flooz'}>Flooz</option>
                        {/* <option value={'Ecobank'}>Ecobank</option> */}
                        <option value={'Cash'}>Paiement à la livraison</option>
                      </select>
                    </form>
                    <p>Référence du paiement</p>
                    <form>
                      <input type="text" placeholder="Référence du paiement" onChange={(value) => { setpaymentDetails({ ...paymentDetails, payemntReference: value.target.value }) }} disabled={paymentDetails?.paymentMethod === 'Cash'} />
                    </form>
                  </div>
                </div>
                <div className="col-sm-5 clearfix">
                  <div className="bill-to">
                    <div className="form-one">
                      <p>{paymentDetails.paymentMethod === 'Ecobank' ? 'Nom et prénoms' : 'Numéro'}:</p>
                      <form>
                        <input type="number" placeholder="Numéro de paiement" onChange={(value) => { setpaymentDetails({ ...paymentDetails, paymentNumber: value.target.value }) }} disabled={paymentDetails?.paymentMethod === 'Cash'} />
                      </form>
                    </div>
                    <div className="form-two">
                      <p>Date de paiement</p>
                      <form>
                        <input type="date" onChange={(value) => { setpaymentDetails({ ...paymentDetails, paymentDate: value.target.value }) }} disabled={paymentDetails?.paymentMethod === 'Cash'} />
                      </form>
                    </div>
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="order-message">
                    <p>Commentaire</p>
                    <textarea name="message" placeholder="Laisser votre commentaire ici" style={{ height: 100 }} rows={5} defaultValue={""} onChange={(value) => { setpaymentDetails({ ...paymentDetails, paymentComment: value.target.value }) }} />
                    <label><input type="checkbox" defaultChecked={true} /> Je déclare avoir effectuer le paiement</label>
                  </div>
                </div>
              </div>
              <a className="btn btn-primary" style={{ marginLeft: '-10px', marginBottom: '25px' }} href="#" onClick={declarePayment}>Continuer</a>
            </div>
            {/* <a className="btn btn-primary" style={{ marginLeft: '-10px', marginBottom: '25px' }} href="#" onClick={openModal}>Choix du mode de paiement</a>
            {(isValid && modeDePaiement !== 'cash') &&
              <a className="btn btn-primary" style={{ marginBottom: '25px' }} href="#" onClick={processpayment}>Payer</a>
            }
            {
              modeDePaiement === 'Cash' &&
              <a className="btn btn-primary" style={{ marginBottom: '25px' }} href="#" onClick={orderCommandWithCash}>Continuer</a>
            } */}
          </div>

        </div>
      </section>
      <Footer />
    </div>
  )
}
