import Footer from "../components/footer";
import Header from "../components/header";

function Apropos() {
    return (
        <div>
            <Header />
            <div className="container">
                <div className="row">
                    <div className="col-sm-12">
                        <h2 className="title text-center"> <strong> A PROPOS </strong></h2>
                    </div>

                    <div className="col-sm-12">
                        <div className="pl-5">
                            <div className="row">
                            <p className="text-center border pb-5">
                                <dfn><b className="">Mee Market</b> est une plateforme en ligne qui offre une variété de produits et de services pour votre satisfaction.</dfn>
                            </p>
                            </div>
                            <div className="row pt-5">
                                <div className="col-sm-6">
                                    <div className="pb-3"> <h4 className="cl">Produits et Services Proposés</h4>
                                        <li>Alimentation</li>
                                        <li>Cosmétologie</li>
                                        <li>Spiritueux et parfumerie</li>
                                        <li>Accessoires divers, ...</li>

                                        <p >
                                            <b><dfn>Nous assurons la livraison de produits frais à votre domicile ou à l'endroit de votre choix.
                                            </dfn></b></p></div>
                                    <div className="pb-3"> <h4 className="cl">Comment avons-nous démarré</h4>
                                        <p>
                                            Nous avons lancé Mee Market après avoir constaté la difficulté pour certaines personnes d'aller dans les marchés pour faire leurs courses.
                                        </p></div>

                                    <div className="pb-3"> <h4 className="cl">Notre Mission</h4>
                                        <p>
                                            Faciliter et permettre à toutes les personnes de faire leurs courses sans stress, peu importe l'endroit où elles se trouvent.
                                        </p></div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="pb-3"> <h4 className="cl">Notre Vision</h4>
                                        <p>
                                            Toucher une part importante de la population et permettre également à ceux qui sont à l'étranger de faire des achats par notre intermédiaire et de les faire livrer à leurs proches.
                                        </p></div>

                                    <div className="pb-3"> <h4 className="cl">Notre Objectif</h4>
                                        <p>
                                            Mettre le client au cœur de nos préoccupations en garantissant une relation personnalisée, une transparence des informations, une écoute attentive, une prestation de qualité et une proximité de notre équipe avec nos clients.
                                        </p></div>

                                    <div className="pb-3"> <h4 className="cl">Le Développement Prévu</h4>
                                        <p>
                                            Nous voulons permettre aux petites et moyennes entreprises locales d'enrichir notre sélection de produits, notamment grâce à des services utiles.
                                        </p></div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <br />
            <Footer />
        </div>
    );
}

export default Apropos;