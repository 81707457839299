import axios, { AxiosRequestConfig } from 'axios';

import { apiConfig } from '../config';
import localStorageService from '../../../services/localStorage.service';
import { useDispatch } from 'react-redux';
import { setCards } from '../../store/slices/cardSlice';
import { setIsLoading, setNotification } from '../../store/slices/loadingSlice';
import { useNavigate } from 'react-router-dom';
import authService from '../../../services/auth.service';
import client from './guest.client';

export const loginClient = axios.create({
    baseURL: apiConfig.baseUrl
});
// const dispatch = useDispatch(/)
// const navigate = useNavigate()

loginClient.interceptors.request.use(
    async (config: any) => {
        try {
            const refreshToken = await localStorageService.get('MeeMarketCustomerRefreshToken');
            const accessToken = await localStorageService.get('MeeMarketCustomerAccessToken');
            // console.warn(refreshToken)
            config.headers = {
                'Authorization': `Bearer ${accessToken}`,
                'Accept': 'application/json',
                'Content-Type': config.headers ? config.headers['Content-Type'] : 'application/json',
                'x-refresh-token': refreshToken as string
            };
            return config;
        } catch (error) {
            return Promise.reject(error);
        }
    },
    (error) => {
        return Promise.reject(error);
    }
);
loginClient.interceptors.response.use((response) => {
    return response;
}, async (error) => {
    const originalConfig = error.config;
    if (error.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;
        // console.warn('expired token')
        const refreshToken = await localStorageService.get('MeeMarketCustomerRefreshToken');
        const accessTokenResponse = await client.get('/auth/refresh-token',
            {
                headers: { 'Authorization': `Bearer ${refreshToken}` }
            });
        const newAccessToken = accessTokenResponse.data?.accessToken;
        // console.warn(accessTokenResponse)
        await localStorageService.set('MeeMarketCustomerAccessToken', newAccessToken);
        loginClient.defaults.headers.common['Authorization'] = `Bearer ' + ${newAccessToken}`;
        return loginClient(originalConfig);
    }
    return Promise.reject(error);
})

