import { RootState } from '..';
import { createSlice } from '@reduxjs/toolkit';
import { ICustomer } from '../../../services/types/user.types';

type UserState = {
    customerDetails: ICustomer,
    modePayement: string
}

const initialState: UserState = {
    customerDetails: {
        birthdate: '',
        email: '',
        firstName: '',
        gender: '',
        id: undefined,
        lastName: '',
        language: '',
        picture: ''
    },
    modePayement: ''
}



const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setCustomerDetails: (state, action) => {
            state.customerDetails = action.payload;
        },
        setModePayement: (state, action) => {
            state.modePayement = action.payload
        }
    }
})

export const { setCustomerDetails, setModePayement } = userSlice.actions;

// Selectors
export const selectCustomerDetails = (state: RootState) => state?.user?.customerDetails;
export const selectModePaiement = (state: RootState) => state?.user?.modePayement;
export default userSlice.reducer;