import { RootState } from '..';
import { createSlice } from '@reduxjs/toolkit';
import { ICard } from '../../../services/interfaces/card.interface';


type ModalSlice = {
    isOpened: boolean,
}

const initialState: ModalSlice = {
    isOpened: false,
}

const modalsice = createSlice({
    name: 'modal',
    initialState,
    reducers: {
        setIsOpened: (state, action) => {
            state.isOpened = action.payload;
        }
    }
})

export const { setIsOpened } = modalsice.actions;

// Selectors
export const selectIsOpened = (state: RootState) => state?.modal?.isOpened;
export default modalsice.reducer;