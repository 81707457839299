// PaymentModal.tsx

import React, { useState } from 'react';
import Modal from 'react-modal';


import './styles/payment.method.css'
import { useDispatch } from 'react-redux';
import { setModePayement } from '../../core/store/slices/userSlice';

interface PaymentModalProps {
    isOpen: boolean;
    onRequestClose: () => void;
    onSelectPayment: (paymentMethod: string) => void;
}



const PaymentModal: React.FC<PaymentModalProps> = ({ isOpen, onRequestClose, onSelectPayment }) => {

    const dispatch = useDispatch()
    const [paymentMethod, setPaymentMethod] = useState<string>()
    const handleSelectPayment = (paymentMethod: string) => {
        onSelectPayment(paymentMethod);
        setPaymentMethod(paymentMethod)
        dispatch(setModePayement(paymentMethod))
        onRequestClose()
    };

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            contentLabel="Choisissez un Mode de Paiement"
            className="payment-modal-content"
            preventScroll={true}
            overlayClassName="modal-overlay"
            shouldCloseOnEsc={false}
            shouldCloseOnOverlayClick={false}
            shouldReturnFocusAfterClose={false}
        >
            <button className="close" onClick={onRequestClose}>&times;</button>
            <h2 className="modal-title">Choisissez un mode de paiement</h2>
            <div className="payment-options">
                <div className={`payment-option ${paymentMethod == "Flooz" ? 'active' : ''}`} onClick={() => handleSelectPayment('Flooz')}>
                    <img src={require('./assets/img/flooz.png')} alt="Flooz" />
                    <p>Flooz</p>
                </div>
                <div className={`payment-option ${paymentMethod == "Tmoney" ? 'active' : ''}`} onClick={() => handleSelectPayment('Tmoney')}>
                    <img src={require('./assets/img/tmoney.png')} alt="Tmoney" />
                    <p>Tmoney</p>
                </div>
                <div className={`payment-option ${paymentMethod == "Cash" ? 'active' : ''}`} onClick={() => handleSelectPayment('Cash')}>
                    <img src={require('./assets/img/paycash.png')} alt="Pay cash" />
                    <p>Espèce</p>
                </div>
            </div>

        </Modal>
    );
};

export default PaymentModal;
