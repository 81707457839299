import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Rating } from 'primereact/rating';
import { Button } from 'primereact/button';
import { Tag } from 'primereact/tag';

import OrderService from '../services/order.services';
import Header from '../components/header';
import Footer from '../components/footer';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { selectCustomerDetails } from '../core/store/slices/userSlice';
import { isConnectedChecked } from '../functions/connected.check';
import paymentServices from '../services/payment.services';
import { environement } from '../environements/environement';


const paymentTaduction = {
    "PENDING": 'En attente',
    "COMPLETED": 'Payé',
    "CANCELED": 'Annulé',
    "REPAY": 'Remboursé'
}

const orderTraduction = {
    "ACCEPTED": 'Commande accepté',
    "PENDING": 'Commande en attente',
    "COMPLETED": 'Commande livrée',
    "PROCESSOFDELIVRY": 'En cours de livraison',
    "CANCELED": 'Commande annulé'
}

export default function RowExpansionDemo() {
    const [products, setProducts] = useState([]);
    const [expandedRows, setExpandedRows] = useState(null);
    const navigate = useNavigate()
    const customerInfo = useSelector(selectCustomerDetails)
    const dispatch = useDispatch()

    isConnectedChecked(dispatch)
        .then(response => {
            if (response === false) {
                navigate('/connexion')
            }
        })

    const download = (paymentRef) => {
        paymentServices.downloadInvoice(paymentRef).
            then(response => {
                // console.warn(response)
                const fileUrl = `${environement.pathDoc+response.document}.pdf`
                fetch(fileUrl, {
                    method: 'GET',
                    headers: {
                      'Content-Type': 'application/pdf',
                    },
                })
                .then((response) => response.blob())
                .then((blob) => {
                  const fileUrl = window.URL.createObjectURL(blob);
                  const link = document.createElement('a');
                  link.href = fileUrl;
                  link.setAttribute('download', `${response.document}.pdf`); // ou tout autre nom de fichier
                  document.body.appendChild(link);
                  link.click();
                  link.parentNode.removeChild(link);
                })
                .catch((error) => console.error('Erreur lors du téléchargement:', error));
                // const url = window.URL.createObjectURL(new Blob([response.data]));
                // console.warn(url)
                // const link = document.createElement('a');
                // link.href = url;
                // link.setAttribute('download', `${paymentRef}.pdf`);

                // Ajout du lien au DOM et déclenchement du téléchargement
                // document.body.appendChild(link);
                // link.click();

                // Suppression du lien après le téléchargement
                // link.parentNode.removeChild(link);
            })
            .catch(err => {
                console.warn(err)
            })
    }

    const redirecttoOrderInfoPage = (orderRef) => {
        navigate(`/checkout/${orderRef}/${customerInfo.email}`)
    }


    useEffect(() => {
        // OrderService.getProductsWithOrdersSmall().then((data) => console.warn(data));
        OrderService.getProductsWithOrdersSmall().then((data) => setProducts(data));
    }, []);

    const statusBodyTemplate = (rowData) => {
        return <Tag style={{ fontSize: 14 }} value={rowData.paymentStatus ? 'Payé' : 'Non payé'} severity={getProductSeverity(rowData)}></Tag>;
    };


    const dateConvertion = (rowData) => {
        const dateString = rowData.date;
        const dateObject = new Date(dateString);

        const options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric', timeZone: 'UTC' };
        const dateFormatted = dateObject.toLocaleDateString('fr-FR', options);

        return <h6 style={{ fontSize: 14 }}>{dateFormatted}</h6>;
    };

    const dateConvertionPayment = (rowData) => {
        const dateString = rowData.createdDate;
        const dateObject = new Date(dateString);

        const options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric', timeZone: 'UTC' };
        const dateFormatted = dateObject.toLocaleDateString('fr-FR', options);

        return <h6 style={{ fontSize: 14 }}>{dateFormatted}</h6>;
    };
    const statusOrder = (rowData) => {
        return <Tag style={{ fontSize: 14 }} value={orderTraduction[rowData.orderStatus]} severity={getOrderStatus(rowData)}></Tag>;
    };

    const statuspayment = (rowData) => {
        return <Tag style={{ fontSize: 14 }} value={paymentTaduction[rowData.status]} severity={paymentStatusFormater(rowData)}></Tag>;
    };

    const orderDetailsIcon = (rowData) => {
        return <Button icon="pi pi-eye" onClick={() => { redirecttoOrderInfoPage(rowData.ref) }} />;
    };

    const downloadIncoice = (rowData) => {
        return <Button icon="pi pi-download" onClick={() => { download(rowData.ref) }} />;
    };

    const formatAmount = (rowData) => {
        return <h6 style={{ fontSize: 14 }}>{rowData.price.toLocaleString('fr-FR', { style: 'decimal' })} XOF</h6>
    }

    const formatDeliveryAmount = (rowData) => {
        return <h6 style={{ fontSize: 14 }}>{rowData.deliveryFees.toLocaleString('fr-FR', { style: 'decimal' })} XOF</h6>
    }

    const formatPaymentAmount = (rowData) => {
        return <h6 style={{ fontSize: 14 }}>{rowData.amount.toLocaleString('fr-FR', { style: 'decimal' })} XOF</h6>
    }
    const getProductSeverity = (OrderList) => {
        switch (OrderList.paymentStatus) {
            case true:
                return 'success';

            case false:
                return 'warning';
            default:
                return null;
        }
    };
    const paymentStatusFormater = (paymentList) => {
        switch (paymentList.status) {
            case 'COMPLETED':
                return 'success';

            case 'CANCELED':
                return 'danger';

            case 'PENDING':
                return 'warning';
            case 'REPAY':
                return 'success'
            default:
                return null;
        }
    };

    const getOrderStatus = (OrderList) => {
        switch (OrderList.orderStatus) {
            case 'COMPLETED':
                return 'success';

            case 'PROCESSOFDELIVRY':
                return 'warning';

            case 'CANCELED':
                return 'danger';

            case 'PENDING':
                return 'warning';

            case 'ACCEPTED':
                return 'info';
            default:
                return null;
        }
    };

    const allowExpansion = (rowData) => {
        return rowData.paymentList.length > 0;
    };
    const rowExpansionTemplate = (data) => {
        // console.warn(data)
        return (
            <div className="p-3">
                <h5 className='bold'>Informations sur le paiement</h5>
                <DataTable value={data.paymentList}>
                    <Column field="id" header="Id" sortable style={{ fontSize: 14 }}></Column>
                    <Column field="ref" header="Référence du paiement" sortable style={{ fontSize: 14 }}></Column>
                    <Column field="amount" header="Total payé" body={formatPaymentAmount} sortable style={{ fontSize: 14 }}></Column>
                    <Column field="status" header="Status" body={statuspayment} sortable style={{ fontSize: 14 }}></Column>
                    <Column field="paymentMethod.name" header="Mode de paiement" sortable style={{ fontSize: 14 }}></Column>
                    {data.methodPay !== 'CASH' && <Column field="paymentMethodNumber" header="Numéro de paiement" sortable style={{ fontSize: 14 }}></Column>}
                    {data.methodPay !== 'CASH' && <Column field="paymentReference" header="Réference de paiement" sortable style={{ fontSize: 14 }}></Column>}
                    <Column field="createdDate" header="Date de paiement" sortable body={dateConvertionPayment} style={{ fontSize: 14 }}></Column>
                    {data.paymentStatus === true && <Column headerStyle={{ width: '4rem' }} body={downloadIncoice}></Column>}
                </DataTable>
            </div >
        );
    };

    return (

        <div>
            <Header />
            <div className="step-one">
                <h2 className="heading">Liste de vos commandes</h2>
            </div>
            <div className="card-prime">
                <DataTable value={products} expandedRows={expandedRows} onRowToggle={(e) => setExpandedRows(e.data)}
                    rowExpansionTemplate={rowExpansionTemplate} dataKey="id" paginator rows={5} columnResizeMode="expand"
                    rowsPerPageOptions={[5, 10, 25, 50]} tableStyle={{ minWidth: '60rem' }}>
                    <Column expander={allowExpansion} style={{ width: '5rem' }} />
                    <Column field="id" header="Id" sortable style={{ width: '5rem', fontSize: 14 }} />
                    <Column field="ref" header="Reference commande" sortable style={{ fontSize: 14 }} />
                    <Column field="price" header="Prix Total" body={formatAmount} sortable style={{ fontSize: 14 }} ></Column>
                    <Column field="deliveryFees" header="Frais de la livraison" body={formatDeliveryAmount} sortable style={{ fontSize: 14 }}></Column>
                    <Column field="orderStatus" header="Statut de la commande" sortable body={statusOrder} style={{ fontSize: 14 }} />
                    <Column field="paymentStatus" header="Statut du paiement" sortable body={statusBodyTemplate} style={{ fontSize: 14 }} />
                    <Column field="date" header="Date de commande" sortable body={dateConvertion} style={{ fontSize: 14 }} />
                    <Column headerStyle={{ width: '4rem' }} body={orderDetailsIcon}></Column>
                </DataTable>
            </div>
            <Footer />
        </div>
    );
}