import { useEffect, useState } from 'react'
import { useNavigate, useParams } from "react-router-dom";

import '../assets/css/product-detail-color-selector.css'
import Header from '../components/header';
import Footer from '../components/footer';
import productsService from '../services/products.service';
import { useDispatch, useSelector } from 'react-redux';
import { setIsLoading, setNotification } from '../core/store/slices/loadingSlice';
import { environement } from '../environements/environement';
import { selectisconnected } from '../core/store/slices/authSlice';
import { ICard } from '../services/interfaces/card.interface';
import { selectCard, setCards } from '../core/store/slices/cardSlice';
import localStorageService from '../services/localStorage.service';

interface IProductDetails {
  id: number;
  brand: string;
  productName: string;
  productDescription: string;
  isTaxable: boolean;
  taxe: number;
  category: string;
  subCategry: string;
  price: number;
  discount: number;
  imageAndColor: {
    image: string;
    color: string;
  }[];
  isAvailable: boolean;
  size: string
}
function ProductDetails() {
  const { productId } = useParams();
  const dispatch = useDispatch()
  const connected = useSelector(selectisconnected)
  const cards = useSelector(selectCard);
  const navigate = useNavigate()
  // Liste des couleurs disponibles
  const [productDetails, setProductDetails] = useState<IProductDetails>()
  const [previewImage, setPreviewImage] = useState('')
  const newPrice = productDetails ? ((productDetails.price) - (productDetails.price * (productDetails.discount / 100))) : 0

  // État local pour suivre la couleur sélectionnée
  const [selectedColor, setSelectedColor] = useState<string>('')

  const handleAddToCart = async (product: ICard['product']) => {
    // console.warn(colors)
    if (connected) {
      dispatch(setIsLoading(true))
      await productsService.addCard(product)
        .then(response => {
          //  console.warn(response)
          dispatch(setIsLoading(false))
        })
        .catch(err => {
          dispatch(setNotification({ type: 'error', message: 'BAD REQUEST', visible: true }))
          dispatch(setIsLoading(false))
          return
        })
    }
    const existingItem = cards.find(item => item.product.id === product.id && item.product.color === product.color);
    if (existingItem) {
      // console.warn(existingItem)
      const updatedCards = cards.map(item =>
        item.product.id === existingItem.product.id && item.product.color === existingItem.product.color
          ? { ...item, product: { ...item.product, qte: item.product.qte + 1 } }
          : item
      );
      dispatch(setCards(updatedCards));
      // console.warn(updatedCards)
      dispatch(setNotification({ type: 'success', message: 'Produit mis à jour avec succès', visible: true }))
      localStorageService.set('cards', JSON.stringify(updatedCards));
    } else {
      const newCard = { id: cards.length + 1, product };
      const updatedCards = [...cards, newCard];
      dispatch(setCards(updatedCards));
      dispatch(setNotification({ type: 'success', message: 'Produit ajouté avec succès', visible: true }))
      localStorageService.set('cards', JSON.stringify(updatedCards));
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = 'Product Details';
    productsService.showProduct(productId as string)
      .then(response => {
        // console.warn(response)
        setProductDetails(response)
        setSelectedColor(response.imageAndColor[0].color);
        setPreviewImage(response.imageAndColor[0].image)
      })
      .catch(err => {
        dispatch(setNotification({ type: 'error', message: 'BAD REQUEST', visible: true }))
        navigate('/')
      })

  }, [productId]);
  return (
    <div>
      <Header />
      <section>
        <div className="container">
          <div className="row">
            <div className="col-sm-9 padding-right">
              <div className="product-details">{/*product-details*/}
                <div className="col-sm-5">
                  <div className="view-product">
                    {previewImage &&
                      <img src={environement.productImagePath + previewImage} alt="" onError={(e) => ((e.target as HTMLImageElement).src = 'https://api.mee-market.com/products/logo.png')}/>
                    }
                  </div>
                  <div id="similar-product" className="carousel slide" data-ride="carousel">
                    {/* Wrapper for slides */}
                    <div className="carousel-inner">
                      <div className="item active">
                        {productDetails?.imageAndColor.map((value, index) => (
                          <img src={environement.productImagePath + value.image} style={{ width: 50 }} onClick={() => {
                            setPreviewImage(value.image)
                            setSelectedColor(value.color)
                            console.warn('clicked')
                          }} alt="image" onError={(e) => ((e.target as HTMLImageElement).src = 'https://api.mee-market.com/products/logo.png')}/>

                        ))}
                      </div>
                    </div>

                  </div>
                </div>
                <div className="col-sm-7">
                  <div className="product-information">
                    <h2>{productDetails?.productName}</h2>
                    <span>
                      {
                        productDetails?.discount !== 0
                          ? <><del>{productDetails?.price.toLocaleString('fr-FR', { style: 'decimal' })} XOF</del> <span>{newPrice.toLocaleString('fr-FR', { style: 'decimal' })} XOF</span> </>
                          : <>.<span>{productDetails?.price.toLocaleString('fr-FR', { style: 'decimal' })} XOF</span></>

                      }
                      <button type="button" className="btn btn-fefault cart"
                        onClick={() => { handleAddToCart({ id: productDetails?.id as number, productName: productDetails?.productName as string, color: selectedColor, price: productDetails?.price as number, discount: productDetails?.discount as number, taxe: productDetails?.taxe as number, qte: 1 }) }}
                      >
                        <i className="fa fa-shopping-cart" />
                        Ajouter au panier
                      </button>
                    </span>
                    <p><b>Disponibilité:</b> {productDetails?.isAvailable ? "En stock" : "Pas en stock"}</p>
                    <p><b>Etat:</b> Neuf</p>
                    <p><b>Marque:</b> {productDetails?.brand}</p>
                    {productDetails?.size &&
                      <p><b>Size:</b> {productDetails?.size}</p>
                    }
                    <p><b>Catégorie:</b> {productDetails?.category}</p>
                    <p><b>Sous-catégorie:</b> {productDetails?.subCategry}</p>
                    <p>Couleur disponible</p>
                    <div className="color-options">
                      {productDetails?.imageAndColor.map((value, index) => (
                        <button
                          key={index}
                          className={`color-option ${selectedColor === value.color ? 'selected' : ''}`}
                          style={{ backgroundColor: value.color.toLowerCase() }}
                          onClick={() => {
                            // handleColorClick(value.color)
                            setSelectedColor(value.color)
                            setPreviewImage(value.image)
                          }
                          }
                        ></button>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              <div className="category-tab shop-details-tab">{/*category-tab*/}
                <div className="col-sm-12">
                  <ul className="nav nav-tabs">
                    <li className="active"><a href="#reviews" data-toggle="tab">Détails</a></li>
                  </ul>
                </div>
                <div className="tab-content">
                  <div className="tab-pane fade active in" id="reviews">
                    <div className="col-sm-12">
                      {/* <ul>
                        <li><a href="#"><i className="fa fa-user" />EUGEN</a></li>
                        <li><a href="#"><i className="fa fa-clock-o" />12:41 PM</a></li>
                        <li><a href="#"><i className="fa fa-calendar-o" />31 DEC 2014</a></li>
                      </ul> */}
                      <p>{productDetails?.productDescription}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  )
}

export default ProductDetails

