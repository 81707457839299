
import { loginClient } from "../core/axios/client/logged-in";
import { IAdressRegister } from "./interfaces/user.interface";

class UserServices {
    static userServiceInstance: UserServices;

    private constructor() { };

    public static getInstance() {
        if (!UserServices.userServiceInstance) {
            UserServices.userServiceInstance = new UserServices();
        }
        return UserServices.userServiceInstance;
    }

    async getDefaultAdress() {
        try {
            const response = await loginClient.get('/adresses/default-address');
            return response.data;
        } catch (error) {
            throw error;
        }
    }

    async getDetails() {
        try {
            const response = await loginClient.get('/informations/details');
            return response.data;
        } catch (error) {
            throw error;
        }
    }

    async addAddress(adresse: IAdressRegister) {
        try {
            const response = await loginClient.post('/adresses/newaddress', adresse);
            return response.data;
        } catch (error) {
            throw error;
        }
    }



}


export default UserServices.getInstance();