// Toast.tsx

import React from 'react';
import { useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { selectNotification, setNotification } from '../core/store/slices/loadingSlice';
import { useAppDispatch } from '../core/store';



const Toast = () => {
    const { type, message, visible } = useSelector(selectNotification);
    const dispatch = useAppDispatch();
    const showToast = () => {
        switch (type) {
            case 'success':
                toast.success(message, { autoClose: 5000, });
                break;
            case 'error':
                toast.error(message, { autoClose: 5000 });
                break;
            case 'info':
                toast.info(message, { autoClose: 5000 });
                break;
            case 'warn':
                toast.warn(message, { autoClose: 5000 });
                break;
            default:
                toast(message, { autoClose: 5000 });
        }
    };

    if (visible) {
        showToast();
        // setTimeout(() => {
            dispatch(setNotification({ type: 'success', message: 'Info title', visible: false }))
        // }, 5000)
    }

    return (
        <>
            <ToastContainer />
        </>
    )
};

export default Toast;
