import { useEffect, useState } from 'react'

import Header from '../components/header';
import Footer from '../components/footer';
import { IContact } from '../services/types/contact.type';
import contactServices from '../services/contact.services';
import { useDispatch } from 'react-redux';
import { setIsLoading, setNotification } from '../core/store/slices/loadingSlice';

function ContactUs() {
  const dispatch = useDispatch()
  const [message, setMessage] = useState<IContact>({ guest_name: '', email_address: '', contact_object: '', contact_message: '' })
  const handleSendMessage = () => {
    dispatch(setIsLoading(true))
    contactServices.createContactMessage(message.guest_name, message.email_address, message.contact_object, message.contact_message)
      .then(response => {
        // console.warn(response)
        dispatch(setIsLoading(false))
        setMessage({ guest_name: '', email_address: '', contact_object: '', contact_message: '' })
        dispatch(setNotification({ type: 'success', message: 'Envoyé avec succès', visible: true }))
      })
      .catch(err => {
        dispatch(setNotification({ type: 'error', message: 'BAD REQUEST', visible: true }))
        // console.warn(err)
      })
  }
  useEffect(() => {
    document.title = 'Contact-Us';
  }, []);
  return (
    <div>
      <Header />
      <div id="contact-page" className="container">
        <div className="bg"  >
          <div className="row">
            <div className="col-sm-12">
              <h2 className="title text-center">NOUS <strong>CONTACTER</strong></h2>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-8" >
              <div className="contact-form">
                <h2 className="title text-center">Ecrivez nous</h2>
                <div className="status alert alert-success" style={{ display: 'none' }} />
                <div id="main-contact-form" className="contact-form row" >
                  <div className="form-group col-md-6">
                    <input type="text" name="name" className="form-control" required placeholder="Nom" value={message.guest_name} onChange={(value) => { setMessage({ ...message, guest_name: value.target.value }) }} />
                  </div>
                  <div className="form-group col-md-6">
                    <input type="email" name="email" className="form-control" required placeholder="Adresse email" value={message.email_address} onChange={(value) => { setMessage({ ...message, email_address: value.target.value }) }} />
                  </div>
                  <div className="form-group col-md-12">
                    <input type="text" name="subject" className="form-control" required placeholder="Objet" value={message.contact_object} onChange={(value) => { setMessage({ ...message, contact_object: value.target.value }) }} />
                  </div>
                  <div className="form-group col-md-12">
                    <textarea name="message" id="message" required className="form-control" rows={8} placeholder="Entrez votre message ici" value={message.contact_message} onChange={(value) => { setMessage({ ...message, contact_message: value.target.value }) }} />
                  </div>
                  <div className="form-group col-md-12">
                    <input type="submit" name="submit" className="btn btn-primary pull-right" defaultValue="Submit" disabled={
                      (message.guest_name === '' || message.email_address === '' || message.contact_object === '' || message.contact_message === '') ?? false
                    } onClick={handleSendMessage} />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="contact-info">
                <h2 className="title text-center">Contact Info</h2>
                <address>
                  {/* <img src={require('../assets/images/mee/logo.png')} style={{ width: '139px', height: '39px' }} /> */}
                  <p>Mee Market</p>
                  <p>Nukafu, Rue des Amandiers</p>
                  <p>Lomé TOGO</p>
                  <p>Mobile: +228 98 30 00 00</p>
                  <p>Email: <a href='mailto:contact@example.com'>contact@mee-market.com</a></p>
                </address>
                <div className="social-networks">
                  <h2 className="title text-center">Réseau social</h2>
                  <ul>
                    <li>
                      <a href=""><i className="fa fa-facebook" /></a>
                    </li>
                    {/* <li>
                      <a href=""><i className="fa fa-twitter" /></a>
                    </li>
                    <li>
                      <a href=""><i className="fa fa-google-plus" /></a>
                    </li>
                    <li>
                      <a href=""><i className="fa fa-youtube" /></a>
                    </li> */}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default ContactUs

