// VotreComponent.tsx

import React from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectisconnected } from '../../core/store/slices/authSlice';
import LoginSignUp from '../Login_SignUp';

const LoginSignUpCheck = () => {
  const isConnected = useSelector(selectisconnected);

  return (
    <>
      {isConnected ? <Navigate to="/" /> : <LoginSignUp />}
    </>
  );
}

export default LoginSignUpCheck;
