import { configureStore } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import authSlice from './slices/authSlice';
import loadingSlice from './slices/loadingSlice';
import cardSlice from './slices/cardSlice';
import modalSlice from './slices/modalSlice';
import userSlice from './slices/userSlice';

const store = configureStore({
    reducer: {
        auth: authSlice,
        loading: loadingSlice,
        card: cardSlice,
        modal: modalSlice,
        user: userSlice
    }
})


export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;


export type RootState = ReturnType<typeof store.getState>
export default store