import { useEffect, useState } from 'react'

import Header from '../components/header';
import Footer from '../components/footer';
import { IOrderCheck } from '../services/types/product.types';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import productsService from '../services/products.service';
import { useDispatch, useSelector } from 'react-redux';
import { setNotification } from '../core/store/slices/loadingSlice';
import { selectisconnected } from '../core/store/slices/authSlice';
import { selectCustomerDetails } from '../core/store/slices/userSlice';

function CheckOrder() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [verifyOrder, setVerifyOrder] = useState<IOrderCheck>({ email_adress: '', order_ref: '' })
  const handleVerifyOrder = () => {
    productsService.verifyOrder(verifyOrder.order_ref, verifyOrder.email_adress)
      .then(response => {
        // console.warn(response)
        if (response.orderToSend) {
          navigate(`/checkout/${verifyOrder.order_ref}/${verifyOrder.email_adress}`)
        } else if (response.isIncorrect === true) {
          dispatch(setNotification({ type: 'error', message: 'Numéro de commande erroné', visible: true }))
        }
      })
      .catch(err => {
        dispatch(setNotification({ type: 'error', message: 'Pas de commande trouvé', visible: true }))
      })
  }
  useEffect(() => {
    document.title = 'Checkout';
  }, []);
  return (
    <div>
      <Header />
      <section id="cart_items">
        <div className="container">
          <div className="breadcrumbs">
            <ol className="breadcrumb">
              <li><a href="">Home</a></li>
              <li className="active">Vérifier une commande</li>
            </ol>
          </div>
          <div className="step-one">
            <h2 className="heading">Renseigner les informations suivantes</h2>
          </div>
          {/* <div className="col-sm-5 clearfix"> */}
          <div className="bill-to">
            <div className="form-one">
              <form>
                <input type="text" placeholder="Réference de la commande" value={verifyOrder?.order_ref} onChange={(value) => { setVerifyOrder({ ...verifyOrder, order_ref: value.target.value }) }} />
              </form>
            </div>
            <div className="form-two">
              <form>
                <input type="text" placeholder="Votre adresse email" value={verifyOrder?.email_adress} onChange={(value) => { setVerifyOrder({ ...verifyOrder, email_adress: value.target.value }) }} />
              </form>
            </div>
            <Button className="btn btn-primary" style={{ marginBottom: 50 }} onClick={handleVerifyOrder}>Rechercher</Button>
          </div>
          {/* </div> */}
        </div>
      </section>
      <Footer />
    </div>
  )
}

export default CheckOrder

