import React, { useEffect, useState } from 'react'
import Modal from 'react-modal';
// import { Button, Modal } from 'flowbite-react';
// import 'tailwindcss/tailwind.css';
// import "bootstrap/dist/css/bootstrap.min.css";
import 'primereact/resources/themes/lara-light-indigo/theme.css';   // theme
import 'primeflex/primeflex.css';                                   // css utility
import 'primeicons/primeicons.css';
import 'primereact/resources/primereact.css';
import 'react-toastify/dist/ReactToastify.css';

import { useAppDispatch } from './core/store'
import localStorageService from './services/localStorage.service'
import { selectisconnected, setIsConnected } from './core/store/slices/authSlice'
import { Loading } from './components/loding.component'
import { useSelector } from 'react-redux'
import { isLoading, selectNotification, setNotification } from './core/store/slices/loadingSlice'
import Toast from './components/notification.component';
import { setCards } from './core/store/slices/cardSlice';
import productsService from './services/products.service';
import { selectIsOpened, setIsOpened } from './core/store/slices/modalSlice';
import userServices from './services/user.services';
import { setCustomerDetails } from './core/store/slices/userSlice';

const customStyles = {
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        zIndex: 1000,
    },
    content: {
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        border: 'none',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        maxWidth: '400px',
        width: '100%',
        padding: '20px',
        background: '#fff',
        borderRadius: '8px',
    },
};

export const App = () => {
    const dispatch = useAppDispatch()
    const visible = useSelector(isLoading)
    const { type } = useSelector(selectNotification)
    const connected = useSelector(selectisconnected)
    const [cardsBb, setCardsDb] = useState([])
    const isconnected = async () => {
        try {
            const token = await localStorageService.get("MeeMarketCustomerAccessToken")
            if (token) dispatch(setIsConnected(true))
            else dispatch(setIsConnected(false))
        } catch (error) {
            // console.warn(error)
            dispatch(setNotification({ type: 'error', message: `Error ${error}`, visible: true }))
        }
    }
    useEffect(() => {
        isconnected()
        if (connected) {
            productsService.getCard()
                .then((response) => {
                    // console.warn(response)
                    if (response[0]) {
                        dispatch(setCards(response))
                        localStorageService.set('cards', JSON.stringify(response));
                    }
                })
                .catch((err) => {
                    dispatch(setCards([]))
                    localStorageService.set('cards', JSON.stringify([]));
                })
        } else {
            const storedCards = JSON.parse(localStorage.getItem('cards') || '[]');
            dispatch(setCards(storedCards));
        }

        userServices.getDetails()
            .then(response => {
                // console.warn(response)
                dispatch(setCustomerDetails(response))
            })
            .catch(err => {
                console.warn(err)
            })

    }, [dispatch, connected])
    return (
        <>
            {/* <Modal
                isOpen={useSelector(selectIsOpened)}
                style={customStyles}
                contentLabel="Example Modal"
            >
                <h2 style={{ fontSize: '1.5rem', fontWeight: 'bold', marginBottom: '1rem' }}>Titre du Modal</h2>
                <p style={{ marginBottom: '2rem', color: '#333' }}>Sous-titre du Modal</p>
                <div style={{ display: 'flex', justifyContent: 'space-between',  }}>
                    <button style={{ background: '#4caf50', color: '#fff', padding: '0.5rem 1rem', borderRadius: '4px', border: 'none', marginRight: '1rem' }}>
                        Oui
                    </button>
                    <button style={{ background: '#f44336', color: '#fff', padding: '0.5rem 1rem', borderRadius: '4px', border: 'none' }}>
                        Non
                    </button>
                </div>
            </Modal> */}

            {visible &&
                <Loading />
            }
            {type &&
                <Toast />
            }
        </>
    )

}