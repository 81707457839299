import { useEffect, useState } from "react";

import productsService from "../services/products.service";
import { useNavigate } from "react-router-dom";


type brand = {
    id: number,
    brandName: string,
    createdDate: string,
    updatedDate: string,
    deletedDate: string | null
}
export const Brands = () => {
    const [brands, setBrands] = useState<brand[]>([])
    const navigate = useNavigate()
    useEffect(() => {
        productsService.getBrands()
            .then((response) => {
                // console.warn(response)
                setBrands(response)
            })

    }, [])
    return (
        <div className="brands_products">{/*brands_products*/}
            <h2>MARQUES</h2>
            <div className="brands-name">
                <ul className="nav nav-pills nav-stacked">
                    {brands.map((value, index) =>
                        <li><a href="" onClick={() => navigate(`/Brand-product/${value.id}`)}> <span className="pull-right"></span>{value.brandName}</a></li>
                    )
                    }
                </ul>
            </div>
        </div>
    );
}