import { ThreeDots } from 'react-loader-spinner'

export const Loading = () => {

    return (
        <div style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 9999, overflow: 'hidden' }}>
            <ThreeDots
                height="80"
                width="80"
                radius="9"
                color="#3879CD"
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                visible={true}
            />
        </div>
    )

}