import { useEffect, useState } from 'react'

import Header from '../components/header';
import Footer from '../components/footer';
import CustomCard from '../components/customCard';
import Carousel from '../components/carrousel.component';
import productsService from '../services/products.service';
import { useDispatch } from 'react-redux';
import { setIsLoading, setNotification } from '../core/store/slices/loadingSlice';
import { LeftDiv } from '../components/LeftDiv.component';

const Home = () => {
  const dispatch = useDispatch()
  const [data, setData] = useState([])
  useEffect(() => {
    dispatch(setIsLoading(true))
    document.title = 'Mee Market';
    document.body.style.overflow = 'hidden';
    productsService.lastedProduct().then(response => {
      // console.warn(response)
      setData(response)
      setTimeout(() => {
        dispatch(setIsLoading(false))
        document.body.style.overflow = 'visible';
      }, 500)
    }).catch(err => {
      // console.warn(err)
      dispatch(setNotification({ type: 'error', message: 'BAD REQUEST', visible: true }))
      dispatch(setIsLoading(false))
      document.body.style.overflow = 'visible';
    })
  }, [dispatch]);

  return (
    <div>
      <Header />
      <section id="slider">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="carousel slide slider-carousel" data-ride="carousel">
                <div className="carousel-inner">
                  <div className="item active">
                    <div className="col-sm-6">
                      <h1><span>MEE </span> MARKET</h1>
                      <h2>Bienvenue sur notre nouvelle plateforme d'achat en ligne</h2>
                      <p>Mee Market est une plateforme en ligne qui offre une variété de produits et de services pour votre satisfaction.</p>
                      <a href="/shop"><button type="button" className="btn btn-primary"> Commencer maintenant</button></a>
                      <p> </p>
                    </div>
                    <div className="col-sm-6">
                      {/* <img src={require('../assets/images/mee/mee-alimentation-generale-livraison.jpg')} className="girl img-responsive" alt="" /> */}
                      <Carousel />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container">
          <div className="row">
            <LeftDiv />
            <div className="col-sm-9 padding-right">
              <div className="features_items">
                <h2 className="title text-center">Nouveautes</h2>
                {
                  data.map((value, index) =>
                    <CustomCard data={value} />
                  )
                }
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  )
}

export default Home