import React, { useEffect, useState } from 'react'

import Header from '../components/header';
import Footer from '../components/footer';
import { useDispatch, useSelector } from 'react-redux';
import { selectCard, setCards } from '../core/store/slices/cardSlice';
import { Button } from 'react-bootstrap';
import localStorageService from '../services/localStorage.service';
import { setIsLoading, setNotification } from '../core/store/slices/loadingSlice';
import { selectisconnected } from '../core/store/slices/authSlice';
import productsService from '../services/products.service';
import userServices from '../services/user.services';
import { IAdress } from '../services/types/user.types';
import { useNavigate } from 'react-router-dom';
import SaveAddressModal from './components/saveAddressModal.component';

function Cards() {
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const cards = useSelector(selectCard)
  const connected = useSelector(selectisconnected)
  const prixTotalTTC = cards.reduce((accumulateur, objetCourant) => accumulateur + (objetCourant.product.price * objetCourant.product.qte + ((objetCourant.product.price * objetCourant.product.qte) * (objetCourant.product.taxe / 100))), 0);
  const prixTotalHT = cards.reduce((accumulateur, objetCourant) => accumulateur + (objetCourant.product.price * objetCourant.product.qte), 0);
  const taxes = cards.reduce((accumulateur, objetCourant) => accumulateur + ((objetCourant.product.price * objetCourant.product.qte) * (objetCourant.product.taxe / 100)), 0);
  const [address, setAddress] = useState<IAdress>()
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => {
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleRemoveFromCart = (productId: number) => {
    dispatch(setIsLoading(true))
    document.body.style.overflow = 'hidden';
    if (connected) {
      productsService.actionsCard(productId, 'REMOVE')
        .then(response => {
          // console.warn(response)
        })
    }
    const updatedCards = cards.filter(item => item.product.id !== productId);
    dispatch(setCards(updatedCards));
    localStorageService.set('cards', JSON.stringify(updatedCards));
    setTimeout(() => {
      dispatch(setIsLoading(false))
      dispatch(setNotification({ type: 'success', message: 'Produit supprimé avec succès', visible: true }))
      document.body.style.overflow = 'visible';
    }, 5000)
  };

  const handleRemoveAllFromCart = () => {
    dispatch(setIsLoading(true))
    document.body.style.overflow = 'hidden';
    if (connected) {
      productsService.actionsCard(1, 'REMOVE_ALL')
        .then(response => {
          // console.warn(response)
        })
        .catch(err => {
          dispatch(setNotification({ type: 'error', message: 'BAD REQUEST', visible: true }))
          dispatch(setIsLoading(false))
          return
        })
    }
    dispatch(setCards([]));
    localStorageService.set('cards', JSON.stringify([]));
    window.scrollTo(0, 0);
    setTimeout(() => {
      dispatch(setIsLoading(false))
      dispatch(setNotification({ type: 'success', message: 'Produits supprimés avec succès', visible: true }))
      document.body.style.overflow = 'visible';

    }, 5000)
  };
  const handleIncreaseFromCart = (productId: number) => {
    // console.warn('ff')
    if (connected) {
      dispatch(setIsLoading(true))
      productsService.actionsCard(productId, 'INCREASE')
        .then(response => {
          // console.warn(response)
          dispatch(setNotification({ type: 'success', message: 'Enrégistré avec succès', visible: true }))
          dispatch(setIsLoading(false))
        })
        .catch(err => {
          dispatch(setNotification({ type: 'error', message: 'BAD REQUEST', visible: true }))
          dispatch(setIsLoading(false))
          return
        })
    }
    const existingItem = cards.find(item => item.product.id === productId);
    const updatedCards = cards.map(item =>
      item.product.id === existingItem?.product.id && item.product.color === existingItem.product.color
        ? { ...item, product: { ...item.product, qte: item.product.qte + 1 } }
        : item
    );
    dispatch(setCards(updatedCards));
    localStorageService.set('cards', JSON.stringify(updatedCards));

  };

  const distanceCalculator = (lat1: number, lon1: number, lat2: number, lon2: number): number => {
    const R: number = 6371; // Rayon moyen de la Terre en kilomètres
    const dLat: number = (lat2 - lat1) * (Math.PI / 180);
    const dLon: number = (lon2 - lon1) * (Math.PI / 180);
    const a: number =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(lat1 * (Math.PI / 180)) * Math.cos(lat2 * (Math.PI / 180)) *
      Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c: number = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance: number = R * c;

    return Math.ceil(distance);;
  }

  const handleDecreaseFromCart = (productId: number, qte: number) => {
    if (qte === 1) return
    if (connected) {
      dispatch(setIsLoading(true))
      productsService.actionsCard(productId, 'DECREASE')
        .then(response => {
          // console.warn(response)
          dispatch(setNotification({ type: 'success', message: 'Enrégistré avec succès', visible: true }))
          dispatch(setIsLoading(false))
        })
        .catch(err => {
          dispatch(setNotification({ type: 'error', message: 'BAD REQUEST', visible: true }))
          dispatch(setIsLoading(false))
          return
        })
    }
    const existingItem = cards.find(item => item.product.id === productId);
    const updatedCards = cards.map(item =>
      item.product.id === existingItem?.product.id
        ? { ...item, product: { ...item.product, qte: item.product.qte - 1 } }
        : item
    );
    dispatch(setCards(updatedCards));
    localStorageService.set('cards', JSON.stringify(updatedCards));
  };
  useEffect(() => {
    document.title = 'Panier';
    if (connected) {
      userServices.getDefaultAdress()
        .then(response => {
          // console.warn(response)
          setAddress(response)
        })
        .catch(err => {
          dispatch(setNotification({ type: 'info', message: 'Pas d\'adresse enrégistrée', visible: true }))
        })
    }
  }, [connected]);

  const createOrder = () => {
    dispatch(setIsLoading(true))
    document.body.style.overflow = 'hidden';
    productsService.createOrder().then(
      response => {
        // console.warn(response)
        if (response.orderRef) {
          dispatch(setIsLoading(false))
          document.body.style.overflow = 'visible';
          dispatch(setCards([]));
          localStorageService.set('cards', JSON.stringify([]));
          dispatch(setNotification({ type: 'warn', message: 'Commande enrégistrée, merci de passer au paiement', visible: true }))
          navigate(`/Order-product/${response.orderRef}`);
        }
      }
    )
      .catch(err => {
        console.warn(err)
      })
  }

  return (
    <div>
      <Header />
      <SaveAddressModal
        isOpen={isModalOpen}
        onClose={closeModal}
      />
      <section id="cart_items">
        <div className="container">
          <div className="breadcrumbs">
            <ol className="breadcrumb">
              <li><a href="">Panier</a></li>
              <li className="active">Listes les élements</li>
            </ol>
          </div>
          {cards.length === 0 ?
            <div style={{ marginBottom: 100 }}>
              <h4>Votre panier est vide</h4>
            </div>
            :
            <div className="table-responsive cart_info">
              <table className="table table-condensed">
                <thead>
                  <tr className="cart_menu">
                    <td className="description">Produit</td>
                    <td className="price">Prix HT</td>
                    <td className="total">Taxes</td>
                    <td className="quantity">Quantités</td>
                    <td className="total">Total TTC</td>
                    <td> Supprimer </td>
                  </tr>
                </thead>
                <tbody>

                  {
                    cards.map((value, index) =>
                      <tr>
                        <td className="cart_description">
                          <h4><a href="" onClick={() => navigate(`/productDetails/${value.product.id}`)}>{value.product.productName}</a></h4>
                          {value.product.color && (value.product.color !== '#fff') &&
                            <div style={{ display: 'flex' }}>
                              <p>Couleur: </p>
                              <p style={{ marginTop: 2, marginLeft: 5, backgroundColor: value.product.color, borderRadius: 50, width: '15px', height: '15px' }}></p>
                            </div>
                          }
                        </td>
                        <td className="cart_price">
                          <p>{value.product.price.toLocaleString('fr-FR', { style: 'decimal' })} XOF</p>
                        </td>
                        <td className="cart_price">
                          <p>{value.product.taxe} %</p>
                        </td>
                        <td className="cart_quantity">
                          <div className="cart_quantity_button">
                            <a className="cart_quantity_up" onClick={() => handleIncreaseFromCart(value.product.id)}> + </a>
                            <input className="cart_quantity_input" type="text" name="quantity" value={value.product.qte} autoComplete="off" size={2} />
                            <a className="cart_quantity_down" onClick={() => handleDecreaseFromCart(value.product.id, value.product.qte)}> - </a>
                          </div>
                        </td>
                        <td className="cart_total">
                          <p className="cart_total_price">{((value.product.price * value.product.qte) + ((value.product.price * value.product.qte) * (value.product.taxe / 100))).toLocaleString('fr-FR', { style: 'decimal' })} XOF</p>
                        </td>
                        <td className="cart_delete">
                          <Button className="cart_quantity_delete" onClick={() => handleRemoveFromCart(value.product.id)}><i className="fa fa-times" /></Button>
                        </td>
                      </tr>
                    )
                  }
                </tbody>
              </table>
            </div>
          }

        </div>
      </section > {/*/#cart_items*/}
      {
        prixTotalTTC !== 0 &&
        <section id="do_action">
          <div className="container">
            {
              connected &&
              <div className="heading">
                <h3>Passez à la commande de vos produits</h3>
                <p>Vérifier vos informations, elle sera livrée à cette adresse</p>
              </div>
            }
            <div className="row">
              {
                connected &&
                <div className="col-sm-6">
                  <div className="chose_area">
                    {address?.id ?
                      <><ul className="user_info">
                        <li className="single_field">
                          <label>Adresse:</label>
                          <h4>{address.adresseName}</h4>
                        </li>
                        <li className="single_field">
                          <label>Rue:</label>
                          <h4>{address.street}</h4>
                        </li>
                        <li className="single_field zip-field">
                          <label>Zip Code:</label>
                          <h4>{address.postalBox}</h4>
                        </li>
                      </ul><ul className="user_info">
                          <li className="single_field">
                            <label>Country:</label>
                            <select>
                              <option>{address.country}</option>
                            </select>
                          </li>
                          <li className="single_field">
                            <label>Ville:</label>
                            <select>
                              <option>{address.city}</option>
                            </select>
                          </li>
                          <li className="single_field zip-field">
                            <label>Numéro de téléphone:</label>
                            <h4>{address.phoneNumber}</h4>
                          </li>
                        </ul>
                        <a className="btn btn-default update" href="#" onClick={openModal}>Modifier l'adresse</a>
                      </>
                      :
                      <>
                        <h4 style={{ marginLeft: 40 }}>Vous n'avez pas d'adresse enregistrée</h4>
                        <a className="btn btn-default update" href="#" onClick={openModal}>Ajouter une adresse</a>
                      </>
                    }
                  </div>
                </div>
              }

              <div className="col-sm-6">
                <div className="total_area">
                  <ul>
                    <li>Prix HT <span>{prixTotalHT.toLocaleString('fr-FR', { style: 'decimal' })} XOF</span></li>
                    <li>Taxes <span>{taxes.toLocaleString('fr-FR', { style: 'decimal' })} XOF</span></li>
                    <li>Total TTC<span>{prixTotalTTC.toLocaleString('fr-FR', { style: 'decimal' })} XOF</span></li>
                    <li>Frais de la livraison <span>{address?.coordonnee ? Math.floor(distanceCalculator(address?.coordonnee.lat as number, address?.coordonnee.lng as number, 6.164897, 1.238962) * 50).toLocaleString('fr-FR', { style: 'decimal' }) + ' XOF' : 'Non disponible, remseigner votre quartier'}</span></li>
                  </ul>
                  <Button className="btn btn-default update" onClick={() => { handleRemoveAllFromCart() }}>Annuler et vider le panier</Button>
                  {
                    connected &&
                    <Button className="btn btn-default update" disabled={address ? false : true} onClick={createOrder}>Commander</Button>
                  }
                </div>
              </div>
            </div>
          </div>
        </section>
      }
      <Footer />
    </div >
  )
}

export default Cards

