import { RootState } from '..';
import { createSlice } from '@reduxjs/toolkit';

type AuthState = {
    isconnected: boolean,
}

const initialState: AuthState = {
    isconnected: false,
}

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setIsConnected: (state, action) => {
            state.isconnected = action.payload;
        },
    }
})

export const { setIsConnected } = authSlice.actions;

// Selectors
export const selectisconnected = (state: RootState) => state?.auth?.isconnected;
export default authSlice.reducer;