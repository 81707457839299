import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setIsLoading, setNotification } from "../core/store/slices/loadingSlice";
import contactServices from "../services/contact.services";
import productsService from "../services/products.service";
import { useNavigate } from "react-router-dom";
import { isValidEmail } from "../functions/email.verifi";


type category = {
    id: number,
    categoryName: string,
    createdDate: string,
    updatedDate: string,
    deletedDate: string | null
}
export default function Footer() {
    const anneeCourante = new Date().getFullYear();
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [email, setEmail] = useState('')
    const [category, setCategory] = useState<category[]>([])
    const handleSaveEmail = () => {
        if (!isValidEmail(email)) {
            dispatch(setNotification({ type: 'error', message: 'Adresse email incorrecte', visible: true }))
            return
        }
        dispatch(setIsLoading(true))
        contactServices.addToNewsletter(email)
            .then(response => {
                console.warn(response)
                dispatch(setIsLoading(false))
                setEmail('')
                if (response.alreadyExist) {
                    dispatch(setNotification({ type: 'info', message: 'Cette adresse existe déjà', visible: true }))
                } else {
                    dispatch(setNotification({ type: 'success', message: 'Enregistré avec succès', visible: true }))
                }
            })
            .catch(err => {
                dispatch(setNotification({ type: 'error', message: 'BAD REQUEST', visible: true }))
                console.warn(err)
            })
    }

    useEffect(() => {
        productsService.getCategories()
            .then((response) => {
                // console.warn(response)
                setCategory(response)
            })

    }, [])
    return (
        <footer id="footer">{/*Footer*/}
            <div className="footer-top">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-2">
                            <div className="companyinfo">
                                <img src={require('../assets/images/mee/logo.png')} style={{ width: '139px', height: '39px' }} />
                                <h5>Nukafu, Rue des Amandiers </h5>
                                <h5>Lomé, TOGO </h5>
                            </div>
                        </div>
                        <div className="col-sm-7">
                            <div className="col-sm-3">
                                <div className="video-gallery text-center">
                                    <a href="#">
                                        <div className="iframe-img">
                                            <img src={require("../assets/images/home/pagne.jpg")} alt="" />
                                        </div>
                                        <div className="overlay-icon">
                                            <i className="fa fa-play-circle-o" />
                                        </div>
                                    </a>
                                    <p>Pagnes</p>

                                </div>
                            </div>
                            <div className="col-sm-3">
                                <div className="video-gallery text-center">
                                    <a href="#">
                                        <div className="iframe-img">
                                            <img src={require("../assets/images/home/packAliment.jpg")} alt="" />
                                        </div>
                                        <div className="overlay-icon">
                                            <i className="fa fa-play-circle-o" />
                                        </div>
                                    </a>
                                    <p>Alimentations</p>
                                    {/* <h2>24 DEC 2014</h2> */}
                                </div>
                            </div>
                            <div className="col-sm-3">
                                <div className="video-gallery text-center">
                                    <a href="#">
                                        <div className="iframe-img">
                                            <img src={require("../assets/images/home/cosmetique.jpeg")} alt="" />
                                        </div>
                                        <div className="overlay-icon">
                                            <i className="fa fa-play-circle-o" />
                                        </div>
                                    </a>
                                    <p>Cosmetiques</p>
                                    {/* <h2>24 DEC 2014</h2> */}
                                </div>
                            </div>
                            <div className="col-sm-3">
                                <div className="video-gallery text-center">
                                    <a href="#">
                                        <div className="iframe-img">
                                            <img src={require("../assets/images/home/magasin_coiffure02.jpg")} alt="" />
                                        </div>
                                        <div className="overlay-icon">
                                            <i className="fa fa-play-circle-o" />
                                        </div>
                                    </a>
                                    <p>Esthétiques</p>
                                    {/* <h2>24 DEC 2014</h2> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-3">
                            <div className="address">
                                <img src={require("../assets/images/home/map.png")} alt="" />
                                <p className="bd">Mee Market, votre plateforme <span className="cent">100%</span> Togolais</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-widget">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-2">
                            <div className="single-widget">
                                <h2>Assistance</h2>
                                <p>Jours de Livraison:</p>
                                <p>Lundi - Samedi</p>
                                <p>9H - 20H</p>
                                <p>Tél: <a href="tel:+22898300000">+228 98 30 00 00</a></p>
                                <p><a href='mailto:contact@example.com'>contact@mee-market.com</a></p>
                            </div>
                        </div>
                        {/* <div className="col-sm-2">
                            <div className="single-widget">
                                <h2>Nos catégories</h2>
                                <ul className="nav nav-pills nav-stacked">
                                    {
                                        category.map((value, index) =>
                                            <li><a href="" onClick={() => navigate(`/Category-product/${value.id}`)}>{value.categoryName}</a></li>
                                        )
                                    }
                                </ul>
                            </div>
                        </div> */}
                        <div className="col-sm-2">
                            <div className="single-widget searchform">
                                <h2>Mode de paiement</h2>
                                <ul className="nav nav-pills nav-stacked">
                                    <li><img src={require('../pages/components/assets/img/flooz.png')} style={{ width: '50%', marginBottom: 10 }} /></li>
                                    <li><img src={require('../pages/components/assets/img/tmoney.png')} style={{ width: '50%' }} /></li>
                                    <li><img src={require('../pages/components/assets/img/ecobank.png')} style={{ width: '50%' }} /></li>
                                    <li><p>Possibilité de payer à la réception de vos produits</p></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-sm-2">
                            <div className="single-widget">
                                <h2>A Propos de nous</h2>
                                <ul className="nav nav-pills nav-stacked">
                                    <li><a href="/apropos">Informations sur la société</a></li>
                                    <li><a href="#">Carrières</a></li>
                                    <li><a href="/contact-us">Service client 24H/7J</a></li>
                                    {/* <li><a href="">Faire une réclamation</a></li> */}
                                </ul>
                            </div>
                        </div>
                        <div className="col-sm-3 col-sm-offset-1">
                            <div className="single-widget">
                                <h2>Rester en contact avec nous</h2>
                                <div className="searchform">
                                    <p>Obtenez les mises à jour les plus récentes  <br />de notre site et être mis à jour vous-même...</p>
                                    <input type="text" placeholder="Votre addresse email" value={email} onChange={(value) => { setEmail(value.target.value) }} />
                                    <button type="submit" className="btn btn-default" onClick={handleSaveEmail}><i className="fa fa-arrow-circle-o-right" /></button>
                                    {/* </form> */}
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-bottom">
                <div className="container">
                    <div className="row" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <p className="pull-left">Copyright © {anneeCourante} MEE MARKET. Tous droits réservés.</p>
                    </div>
                </div>
            </div>
        </footer>
    );
}