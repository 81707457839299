import { useDispatch, useSelector } from "react-redux";
import { selectCard, setCards } from "../core/store/slices/cardSlice";
import { ICard } from "../services/interfaces/card.interface";
import { Button } from "react-bootstrap";
import localStorageService from "../services/localStorage.service";
import { setIsLoading, setNotification } from "../core/store/slices/loadingSlice";
import { useNavigate } from "react-router-dom";
import { environement } from "../environements/environement";
import { selectisconnected } from "../core/store/slices/authSlice";
import productsService from "../services/products.service";
import { pictureUrl } from "../core/axios/config";

const ProductCard = (props: any) => {

  const cards = useSelector(selectCard);
  const dispatch = useDispatch();
  const nagivate = useNavigate()
  const connected = useSelector(selectisconnected)

  const handleAddToCart = async (product: ICard['product']) => {
    if (connected) {
      dispatch(setIsLoading(true))
      await productsService.addCard(product)
        .then(response => {
          //  console.warn(response)
          dispatch(setIsLoading(false))
        })
        .catch(err => {
          dispatch(setNotification({ type: 'error', message: 'BAD REQUEST', visible: true }))
          dispatch(setIsLoading(false))
          return
        })
    }
    const existingItem = cards.find(item => item.product.id === product.id && item.product.color === product.color);
    if (existingItem) {
      const updatedCards = cards.map(item =>
        item.product.id === existingItem.product.id && item.product.color === existingItem.product.color
          ? { ...item, product: { ...item.product, qte: item.product.qte + 1 } }
          : item
      );
      dispatch(setCards(updatedCards));
      dispatch(setNotification({ type: 'success', message: 'Produit mis à jour avec succès', visible: true }))
      localStorageService.set('cards', JSON.stringify(updatedCards));
    } else {
      const newCard = { id: cards.length + 1, product };
      const updatedCards = [...cards, newCard];
      dispatch(setCards(updatedCards));
      dispatch(setNotification({ type: 'success', message: 'Produit ajouté avec succès', visible: true }))
      localStorageService.set('cards', JSON.stringify(updatedCards));
    }
  };

  return (
    <div className="col-sm-4" >
      <div className="product-image-wrapper">
        <div className="single-products">
          <div className="productinfo text-center">
            <img src={environement.productImagePath + props.data.defaultImage} style={{ width: '100%', aspectRatio: 4 / 3, objectFit: 'cover' }} alt="" onError={(e) => ((e.target as HTMLImageElement).src = 'https://api.mee-market.com/products/logo.png')} />
            {
              props.data.discount !== 0
                ? <><del>{props.data.price.toLocaleString('fr-FR', { style: 'decimal' })}</del> XOF <h2>{((props.data.price) - (props.data.price * (props.data.discount / 100))).toLocaleString('fr-FR', { style: 'decimal' })} XOF</h2> </>
                : <>.<h2>{props.data.price.toLocaleString('fr-FR', { style: 'decimal' })} XOF</h2></>

            }
            <p>{props.data.productName}</p>
            <Button
              className="btn btn-default add-to-cart"
              onClick={() => { handleAddToCart({ id: props.data.id, productName: props.data.productName, color: props.data.defaultColor, price: props.data.price, discount: props.data.discount, taxe: props.data.taxe, qte: 1 }) }}
            ><i className="fa fa-shopping-cart" />Ajouter au panier</Button>
          </div>
          {/* <div className="product-overlay">
            <div className="overlay-content">
              <h2>{props.data.price} FCFA</h2>
              <p>{props.data.title}</p>
              <a href="#" className="btn btn-default add-to-cart"><i className="fa fa-shopping-cart" />Ajouter au panier</a>
            </div> */}
          {/* </div> */}
        </div>
        <div className="choose">
          <ul className="nav nav-pills nav-justified">
            {/* <li><a href="#"><i className="fa fa-plus-square" />Ajouter à la liste des souhaits</a></li> */}
            <li><a href='' onClick={() => nagivate(`/productDetails/${props.data.id}`)}><i className="fa fa-plus-square" />Plus de détails</a></li>
          </ul>
        </div>
      </div>
    </div>
  );
}


export default ProductCard;