import { loginClient } from "../core/axios/client/logged-in";

class PaymentService {
    static paymentServiceInstance: PaymentService;

    private constructor() { };

    public static getInstance() {
        if (!PaymentService.paymentServiceInstance) {
            PaymentService.paymentServiceInstance = new PaymentService();
        }
        return PaymentService.paymentServiceInstance;
    }

    async paygatePayment(phoneNumber: string, amount: number, paymentMethod: string, orderRef: string) {
        try {
            const response = await loginClient.post('/payment/paygate/pay', {
                phoneNumber, amount, paymentMethod, orderRef
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    }
    async declarePayment(phoneNumber: string,
        amount: number,
        paymentMethod: string,
        orderRef: string,
        transactionId: string,
        transactionDate: string,
        transactionComment: string) {
        try {
            const response = await loginClient.post('/payment/declaration', {
                phoneNumber,
                amount,
                paymentMethod,
                orderRef,
                transactionId,
                transactionDate,
                transactionComment
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    }
    async downloadInvoice(orderRef: string) {
        try {
            const response = await loginClient.get(`/facture/invoice/${orderRef}`);
            return response.data;
        } catch (error) {
            throw error;
        }
    }
}


export default PaymentService.getInstance();