import { useEffect, useState } from "react";
import productsService from "../services/products.service";
import { useNavigate } from "react-router-dom";

type category = {
    id: number,
    categoryName: string,
    createdDate: string,
    updatedDate: string,
    deletedDate: string | null
}
export const Category = () => {

    const [category, setCategory] = useState<category[]>([])
    const navigate = useNavigate()
    useEffect(() => {
        productsService.getCategories()
            .then((response) => {
                // console.warn(response)
                setCategory(response)
            })

    }, [])


    return (
        <>
            <h2>Catégories</h2>
            <div className="panel-group category-products" id="accordian">
                {category.map((value, index) =>
                    <div className="panel panel-default">
                        <div className="panel-heading">
                            <h4 className="panel-title"><a href="" onClick={() => navigate(`/Category-product/${value.id}`)}>{value.categoryName}</a></h4>
                        </div>
                    </div>
                )
                }
            </div >
        </>

    );
}