import { createBrowserRouter } from 'react-router-dom';


import ErrorPage from '../pages/ErrorPage';
import Cards from '../pages/Cards';
import Checkout from '../pages/checkout';
import ProductDetails from '../pages/ProductDetails';
import ContactUs from '../pages/ContactUs';
import Shop from '../pages/Shop';
import LoginSignUpCheck from '../pages/components/login.components';
import Home from '../pages/Home';
import { Order } from '../pages/order';
import OrdersList from '../pages/Orders';
import SearchProduct from '../pages/SearchProduct';
import Apropos from '../pages/Apropos';
import MonCompte from '../pages/MonCompte';
import CheckOrder from '../pages/CheckOrder';
import BrandProduct from '../pages/BrandProduct';
import CategoryProduct from '../pages/CategoryProduct';
import VerifyEmail from '../pages/VerifyEmail';
import PasswordReset from '../pages/ChangedPassword';

export const router = createBrowserRouter([
    {
        path: '/',
        element: <Home />,
        errorElement: <ErrorPage />,
        children: []
    },
    {
        path: 'connexion',
        element: <LoginSignUpCheck />,
        errorElement: <ErrorPage />,
        children: []
    },
    {
        path: 'cards',
        element: <Cards />,
        errorElement: <ErrorPage />,
        children: []
    },
    {
        path: 'checkout/:orderRef/:email_adress',
        element: <Checkout />,
        errorElement: <ErrorPage />,
        children: []
    },
    {
        path: 'check-order',
        element: <CheckOrder />,
        errorElement: <ErrorPage />,
        children: []
    },
    {
        path: 'ProductDetails/:productId',
        element: <ProductDetails />,
        errorElement: <ErrorPage />,
        children: []
    },
    {
        path: 'Order-product/:orderRef',
        element: <Order />,
        errorElement: <ErrorPage />,
        children: []
    },
    {
        path: 'orders-list',
        element: <OrdersList />,
        errorElement: <ErrorPage />,
        children: []
    },
    {
        path: 'Contact-us',
        element: <ContactUs />,
        errorElement: <ErrorPage />,
        children: []
    },
    {
        path: 'apropos',
        element: <Apropos />,
        errorElement: <ErrorPage />,
        children: []
    },
    {
        path: 'account',
        element: <MonCompte />,
        errorElement: <ErrorPage />,
        children: []
    },

    {
        path: 'Shop',
        element: <Shop />,
        errorElement: <ErrorPage />,
        children: []
    },
    {
        path: 'Search-product/:productName',
        element: <SearchProduct />,
        errorElement: <ErrorPage />,
        children: []
    },
    {
        path: 'Category-product/:categoryId',
        element: <CategoryProduct />,
        errorElement: <ErrorPage />,
        children: []
    },
    {
        path: 'Brand-product/:brandId',
        element: <BrandProduct />,
        errorElement: <ErrorPage />,
        children: []
    },
    {
        path: 'verify-email/:token',
        element: <VerifyEmail />,
        errorElement: <ErrorPage />,
        children: []
    },
    {
        path: 'reset-password/:token',
        element: <PasswordReset />,
        errorElement: <ErrorPage />,
        children: []
    }
]
);
