import { loginClient } from "../core/axios/client/logged-in";

interface OrderList {
    id: number;
    ref: string;
    orderStatus: string;
    price: number;
    paymentStatus: string;
    deliveryFees: number;
    paymentList?: IPayment[];
    date: string;
}

interface IPayment {
    id: number;
    ref: string;
    amount: number;
    isOnlinePayment: boolean;
    txReference?: string | null;
    identifier?: string | null;
    paymentReference?: string | null;
    paymentDateTime?: string | null;
    status: string;
    methodPay?: string | null;
    partnerMethodPay?: string | null;
    paymentMethodNumber?: string | null;
    createdDate: Date;
    updatedDate: Date;
    deletedDate?: Date | null;
}


class OrderService {
    static orderServiceInstance: OrderService;

    private constructor() { };

    public static getInstance() {
        if (!OrderService.orderServiceInstance) {
            OrderService.orderServiceInstance = new OrderService();
        }
        return OrderService.orderServiceInstance;
    }

    async getOrderPaymentStatus(orderRef: string) {
        try {
            const response = await loginClient.get(`/orders/verif-payment-order/${orderRef}`);
            return response.data;
        } catch (error) {
            throw error;
        }
    }

    async getProductsWithOrdersData(): Promise<OrderList[]> {
        try {
            const response = await loginClient.get('/orders/list-order')
            return response.data;
        } catch (error) {
            throw error
        }
    }
    async getProductsWithOrdersSmall(): Promise<OrderList[]> {
        return Promise.resolve((await this.getProductsWithOrdersData()));
        // return Promise.resolve((await this.getProductsWithOrdersData()).slice(0, 10));
    }

    async getProductsWithOrders(): Promise<OrderList[]> {
        return Promise.resolve(await this.getProductsWithOrdersData());
    }

}


export default OrderService.getInstance();

