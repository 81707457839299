import { setNotification } from "../core/store/slices/loadingSlice";


export const isConnectedChecked = async (dispatch: any) => {
    const isLoggedIn = localStorage.getItem('MeeMarketCustomerAccessToken');

    if (!isLoggedIn) {
        dispatch(setNotification({ type: 'error', message: 'Accès non authorisé', visible: true }));
        return false
    }
    else {
        return true
    }
}
