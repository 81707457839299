import { RootState } from '..';
import { createSlice } from '@reduxjs/toolkit';


type NotificationType = {
    type: 'success' | 'error' | 'info' | 'warn';
    message: string,
    visible: boolean;
}

type LoadingState = {
    isVisible: boolean,
    notification: NotificationType
}

const initialState: LoadingState = {
    isVisible: false,
    notification: {
        type: 'success',
        message: 'Info title',
        visible: false,
    } as NotificationType
}

const authSlice = createSlice({
    name: 'loading',
    initialState,
    reducers: {
        setIsLoading: (state, action) => {
            state.isVisible = action.payload;
        },
        setNotification: (state, action: { payload: NotificationType }) => {
            state.notification = action.payload
        }
    }
})

export const { setIsLoading, setNotification } = authSlice.actions;

// Selectors
export const isLoading = (state: RootState) => state?.loading?.isVisible;
export const selectNotification = (state: RootState) => state?.loading?.notification;

export default authSlice.reducer;