import Modal from 'react-modal';
import './styles/address.save.css'
// import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
// import { IAdress } from '../../services/types/user.types';
import React, { useEffect, useState } from 'react'
import { selectisconnected } from '../../core/store/slices/authSlice';
import { useDispatch, useSelector } from 'react-redux';
import userServices from '../../services/user.services';
import { setNotification } from '../../core/store/slices/loadingSlice';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';
import { IAdressRegister } from '../../services/interfaces/user.interface';
// import { selectCustomerDetails } from '../../core/store/slices/userSlice';


interface SaveAddressModalProps {
  isOpen: boolean;
  onClose: () => void;

}

// const mapContainerStyle = {
//   marginTop: 20,
//   width: '42vw',
//   height: '50vh',
// };


// const center = {
//   lat: 48.8566, // Exemple de latitude pour Paris, France
//   lng: 2.3522,
// };

const SaveAddressModal: React.FC<SaveAddressModalProps> = ({ isOpen, onClose }) => {
  const [newAddress, setNewAddress] = useState<IAdressRegister>({
    adresseName: '',
    adresseType: 'HOME',
    country: 'TOGO',
    city: 'Lome',
    phoneNumber: '',
    whatsappPhoneNumber: '',
    postalBox: '',
    street: '',
    coordonnee: {
      lng: 0,
      lat: 0
    },
    quartier: ''
  });

  // const userId = useSelector(selectCustomerDetails).id
  const connected = useSelector(selectisconnected);
  const dispatch = useDispatch();
  const [value, setValue] = useState<any>();
  const handleSelectAdress = (selectedValue: any) => {
    setValue(selectedValue)
    // console.warn(selectedValue.label)
    geocodeByAddress(selectedValue?.label)
      .then(results => getLatLng(results[0]))
      .then(({ lat, lng }) => {
        // console.warn({ lat, lng })
        const coordonnee = {
          lng: lng,
          lat: lat
        }
        setNewAddress({ ...newAddress, coordonnee: coordonnee, quartier: selectedValue.label })
        // console.log('Successfully got latitude and longitude', { lat, lng })
      }
      );
  }
  const saveNewAddress = () => {

    if (!(newAddress.adresseName && newAddress.adresseType && newAddress.city && newAddress.country && newAddress.phoneNumber && newAddress.whatsappPhoneNumber
      && newAddress.coordonnee && newAddress.street && newAddress.postalBox && newAddress.quartier)) {
      // console.warn(newAddress)
      dispatch(setNotification({
        type: 'error', message: 'Remplissez tous les champs', visible: true
      }))
      return
    }
    else {
      userServices.addAddress(newAddress)
        .then((response) => {
          if (response.updated) {
            onClose();
            dispatch(setNotification({ type: 'success', message: 'Adresse mis à jour avec succès', visible: true }))
          } else {
            onClose();
            dispatch(setNotification({ type: 'success', message: 'Adresse enrégistrée', visible: true }));
          }
          // console.log('succes')
          window.location.reload();
        })
        .catch((error) => {
          dispatch(setNotification({ type: 'error', message: 'Erreur dans l\'ajout de l\'adresse', visible: true }))
          // console.error('Erreur lors de l\'enregistrement de l\'adresse :', error);
          // Gérez l'erreur selon vos besoins
        });
    }
  };
  // console.log(newAddress);

  // const handleMarkerDragEnd = (event: google.maps.MapMouseEvent) => {
  //   if (event.latLng) {
  //     console.log('Nouvelle position du marqueur :', event.latLng.toJSON());
  //   } else {
  //     console.error('L\'événement n\'a pas de propriété latLng.');
  //   }
  // };

  useEffect(() => {
    document.title = 'adresse';
    if (connected) {
      userServices.getDefaultAdress()
        .then(response => {
          if (response.id) {
            setNewAddress(response);
          }
        })
        .catch(err => {
          // dispatch(setNotification({ type: 'info', message: 'Pas d\'adresse enrégistrée', visible: true }));
        });
    }
  }, [connected, dispatch]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      className="modal-content"
      overlayClassName="modal-overlay"
    >
      {/* <div className="container"> */}
      {/* <div className="row"> */}
      <button className="close" onClick={onClose}>&times;</button>
      <h5 className='titleStyle'>{newAddress.adresseName ? 'Modification d\'une adresse' : 'Enrégistrement d\'une adresse'}</h5>
      {/* <div className=""> */}
      {/* <div> */}
      <div className="form-container col-sm-12 d-flex formPosition">
        {connected && (
          <>
            <div className=' col-md-6 col-lg-6 col-sm-12 pr-5'>
              <h5>Adresse</h5>
              <input placeholder="Entrer le nom de l'adresse"  className='put mb-3 col-12' value={newAddress.adresseName} required onChange={(e) => setNewAddress({ ...newAddress, adresseName: e.target.value })} />
              <h5>Rue</h5>
              <input placeholder="Entrer le nom de rue" type="text" className='put mb-3 col-12' value={newAddress.street} required onChange={(e) => setNewAddress({ ...newAddress, street: e.target.value })} />
              <h5>Boite postale</h5>
              <input placeholder="Entrer la boite postale" type="text" className='put mb-3 col-12' value={newAddress.postalBox} required onChange={(e) => setNewAddress({ ...newAddress, postalBox: e.target.value })} />

              <h5>Type d'adresse</h5>
              <select name="type" id="type" className='mb-3' style={{ paddingTop: '10px', paddingBottom: '10px' }} value={newAddress.adresseType} onChange={(e) => setNewAddress({ ...newAddress, adresseType: e.target.value })}>
                <option value="HOME">Domicile</option>
                <option value="OFFICE">Bureau</option>
              </select>

              <h5>Pays</h5>
              <select name="country" id="country" style={{ paddingTop: '10px', paddingBottom: '10px' }} required value={newAddress.country} onChange={(e) => setNewAddress({ ...newAddress, country: e.target.value })}>
                <option value="TOGO">TOGO</option>
              </select>
            </div>
            <div className=' col-md-6 col-lg-6 col-sm-12'>
              <h5>Ville</h5>
              <select name="country" id="country" className='mb-3' style={{ paddingTop: '10px', paddingBottom: '10px' }} required value={newAddress.city} onChange={(e) => setNewAddress({ ...newAddress, city: e.target.value })}>
                <option value="Lome">Lome</option>
                <option value="Kara">kara</option>
              </select>
              <h5>Numero de téléphone</h5>
              <input type="tel" className='put col-12 mb-3' value={newAddress.phoneNumber} onChange={(e) => setNewAddress({ ...newAddress, phoneNumber: e.target.value })} />

              <h5>Numero de téléphone Whatsapp</h5>
              <input type="tel" className='put col-12' value={newAddress.whatsappPhoneNumber} onChange={(e) => setNewAddress({ ...newAddress, whatsappPhoneNumber: e.target.value })} /></div>
          </>)}
        <div className='col-md-6 col-lg-6 col-sm-12'>
          <h5>Quartier</h5>

          <GooglePlacesAutocomplete
            apiKey="AIzaSyD-VgRM06hSmjfK-0Tt-qQgK-PQIS31djY"
            minLengthAutocomplete={2}

            selectProps={{
              placeholder: 'Entrer votre quartier',
              defaultInputValue: newAddress.quartier,
              value,
              onChange: (value, jj) => {
                // console.warn(value)
                handleSelectAdress(value)
              }
            }}

          />

          <button type="submit" className="btn btn-primary save" onClick={saveNewAddress}>Enregistrer Adresse</button>

        </div>



      </div>
      {/* <div className="col-sm-2">
          <div>
            <GooglePlacesAutocomplete
              apiKey="AIzaSyBO1VTVV4N8uqwW53DSQric8sQ6MWiRRg0"
              minLengthAutocomplete={2}
              selectProps={{
                value,
                onChange: (value, jj) => {
                  console.warn(value)
                  handleSelectAdress(value)
                }
              }}

            />
          </div>
          <LoadScript googleMapsApiKey="">
            <GoogleMap mapContainerStyle={mapContainerStyle} center={center} zoom={10}>
              <Marker
                position={center}
                draggable={true}
                onDragEnd={handleMarkerDragEnd}
              />
            </GoogleMap>
          </LoadScript>
          <button type="submit" className="btn btn-primary save" style={{ textAlign: 'center', margin: '40px' }} onClick={saveNewAddress}>Enregistrer Adresse</button>
        </div> */}

      {/* </div> */}
      {/* </div>
      </div> */}
    </Modal>

  );
};

export default SaveAddressModal;


