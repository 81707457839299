import { RootState } from '..';
import { createSlice } from '@reduxjs/toolkit';
import { ICard } from '../../../services/interfaces/card.interface';




type CardSlice = {
    cards: ICard[]
}

const initialState: CardSlice = {
    cards: [],
}

const cardSlice = createSlice({
    name: 'card',
    initialState,
    reducers: {
        setCards: (state, action) => {
            state.cards = action.payload;
        },
        removeFromCart: (state, action) => {
            state.cards = state.cards.filter(card => card.product.id !== action.payload.productId);
            localStorage.setItem('cards', JSON.stringify(state.cards));
        },
    }
})

export const { setCards } = cardSlice.actions;

// Selectors
export const selectCard = (state: RootState) => state?.card?.cards;
export default cardSlice.reducer;