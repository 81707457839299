import { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap';

import Header from '../components/header';
import Footer from '../components/footer';
import { useNavigate, useParams } from 'react-router-dom';
import productsService from '../services/products.service';
import { useDispatch, useSelector } from 'react-redux';
import { setNotification } from '../core/store/slices/loadingSlice';
import { IOrder } from '../services/types/product.types';
import { selectCustomerDetails } from '../core/store/slices/userSlice';
import { IAdress, ICustomer } from '../services/types/user.types';
import { selectisconnected } from '../core/store/slices/authSlice';


const orderStatus = {
  ACCEPTED: {
    labelle: 'Accepté',
    status: 'btn-success'
  },
  PENDING: {
    labelle: 'En attente de validation',
    status: 'btn-warning'
  },
  COMPLETED: {
    labelle: 'Livré',
    status: 'btn-success'
  },
  PROCESSOFDELIVRY: {
    labelle: 'Livraison en cours',
    status: 'btn-info'
  },
  CANCELED: {
    labelle: 'Annulé',
    status: 'btn-danger'
  }
}
interface IOrderSatusCheck {
  id: string,
  orderStatus: keyof typeof orderStatus;
  paid: boolean,
  deliveryFees: number
}


function Checkout() {
  const { orderRef, email_adress } = useParams();
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const connected = useSelector(selectisconnected)
  const [address, setAddress] = useState<IAdress>()
  const [orderDetails, setOrderDetails] = useState<IOrder[]>([])
  const [customerInfo, setCustomerInfo] = useState<ICustomer>()
  const [orderInfo, setOrderInfo] = useState<IOrderSatusCheck | undefined>()
  const prixTotalTTC = orderDetails.reduce((accumulateur, objetCourant) => accumulateur + (objetCourant.product.price * objetCourant.product.qte + ((objetCourant.product.price * objetCourant.product.qte) * (objetCourant.product.taxe / 100))), 0);
  const prixTotalHT = orderDetails.reduce((accumulateur, objetCourant) => accumulateur + (objetCourant.product.price * objetCourant.product.qte), 0);
  const taxes = orderDetails.reduce((accumulateur, objetCourant) => accumulateur + ((objetCourant.product.price * objetCourant.product.qte) * (objetCourant.product.taxe / 100)), 0);

  useEffect(() => {

    document.title = 'Checkout';
    productsService.verifyOrder(orderRef as string, email_adress as string)
      .then(response => {
        // console.warn(response)
        if (response.orderToSend) {
          // console.warn(response)
          setCustomerInfo(response.existingCustomer)
          setOrderDetails(response.ordersLsits)
          setAddress(response.defaultAdress)
          setOrderInfo(response.orderToSend)
          // console.warn(orderStatus[orderInfo?.orderStatus ?? 'PENDING'].status)
        } else if (response.isIncorrect === true) {
          dispatch(setNotification({ type: 'error', message: 'Numéro de commande erroné', visible: true }))
          navigate(`/check-order`)
        }
      })
      .catch(err => {
        dispatch(setNotification({ type: 'error', message: 'Pas de commande trouvé', visible: true }))
      })
  }, [orderRef, email_adress]);
  return (
    <div>
      <Header />
      <section id="cart_items">
        <div className="container">
          <div className="breadcrumbs">
            <ol className="breadcrumb">
              <li><a href="">Accueil</a></li>
              <li className="active">Commande</li>
            </ol>
          </div>{/*/breadcrums*/}
          <div className="step-one">
            <h2 className="heading">Récapitulatif de la commande</h2>
          </div>
          <div className="checkout-options">
            <h3>Réference de la commande</h3>
            <p>{orderRef}</p>
            <ul className="nav">
              <li>
                <label> Status de la commande:</label>
              </li>
              <li>
                <span className={`btn ${orderStatus[orderInfo?.orderStatus ?? 'PENDING'].status}`}>{orderStatus[orderInfo?.orderStatus ?? 'PENDING'].labelle}</span>
              </li>
              <li>
                <label> Status du payement:</label>
              </li>
              <li>
                <span className={orderInfo?.paid ? "btn btn-success" : "btn btn-danger"}>{orderInfo?.paid ? "Payé" : "Non payé"}</span>
              </li>
            </ul>
          </div>
          <div className="register-req">
            <p>Vérifier vos informations personnelles et votre adresse de livraison</p>
          </div>
          <div className="shopper-informations">
            <div className="row">
              <div className="col-sm-3">
                <div className="shopper-info">
                  <p>Vos informations</p>
                  <form>
                    <input type="text" placeholder="Display Name" value={customerInfo?.firstName} disabled />
                    <input type="text" placeholder="Display Name" value={customerInfo?.lastName} disabled />
                    <input type="text" placeholder="User Name" value={customerInfo?.email} disabled />
                  </form>
                </div>
              </div>
              <div className="col-sm-5 clearfix">
                <div className="bill-to">
                  <p>Information de livraison</p>
                  <div className="form-one">
                    <form>
                      <input type="text" placeholder="Email*" value={customerInfo?.email} disabled />
                      <input type="text" placeholder="Address 1 *" value={address?.adresseName} disabled />
                      <input type="text" placeholder="Mobile Phone" value={address?.phoneNumber} disabled />
                    </form>
                  </div>
                  <div className="form-two">
                    <form>
                      <input type="text" placeholder="Zip / Postal Code *" value={address?.postalBox} disabled />
                      <select>
                        <option>{address?.city}</option>
                      </select>
                      <input type="text" placeholder="Street" value={address?.street} disabled />
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="review-payment">
            <h2>Récapitulatif des articles</h2>
          </div>
          <div className="table-responsive cart_info">
            <table className="table table-condensed">
              <thead>
                <tr className="cart_menu">
                  <td className="description">Produit</td>
                  <td className="price">Prix HT</td>
                  <td className="total">Taxes</td>
                  <td className="quantity">Quantités</td>
                  <td className="total">Total TTC</td>
                </tr>
              </thead>
              <tbody>
                {
                  orderDetails.map((value, index) =>
                    <tr>
                      <td className="cart_description">
                        <h5><a href="">{value.product.productName}</a></h5>
                        {value.product.color && (value.product.color !== '#fff') &&
                          <div style={{ display: 'flex' }}>
                            <p>Couleur: </p>
                            <p style={{ marginTop: 2, marginLeft: 5, backgroundColor: value.product.color, borderRadius: 50, width: '15px', height: '15px' }}></p>
                          </div>
                        }
                      </td>
                      <td className="cart_price">
                        <p>{value.product.price.toLocaleString('fr-FR', { style: 'decimal' })} XOF</p>
                      </td>
                      <td className="cart_price">
                        <p>{value.product.taxe} %</p>
                      </td>
                      <td className="cart_quantity">
                        <div className="cart_quantity_button">
                          <h4>{value.product.qte}</h4>
                        </div>
                      </td>
                      <td className="cart_total">
                        <p className="cart_total_price">{((value.product.price * value.product.qte) + ((value.product.price * value.product.qte) * (value.product.taxe / 100))).toLocaleString('fr-FR', { style: 'decimal' })} XOF</p>
                      </td>
                    </tr>
                  )
                }
                <tr>
                  <td colSpan={3}>&nbsp;</td>
                  <td colSpan={2}>
                    <table className="table table-condensed total-result">
                      <tbody><tr>
                        <td>Prix total HT</td>
                        <td>{prixTotalHT.toLocaleString('fr-FR', { style: 'decimal' })} XOF</td>
                      </tr>
                        <tr>
                          <td>Taxe</td>
                          <td>{taxes.toLocaleString('fr-FR', { style: 'decimal' })} XOF</td>
                        </tr>
                        <tr className="shipping-cost">
                          <td>Frais de livraison</td>
                          <td>{orderInfo?.deliveryFees.toLocaleString('fr-FR', { style: 'decimal' })} XOF</td>
                        </tr>
                        <tr>
                          <td>Total TTC</td>
                          <td><span>{ orderInfo?.deliveryFees ? Number(prixTotalTTC + orderInfo?.deliveryFees).toLocaleString('fr-FR', { style: 'decimal' }) : Number(prixTotalTTC).toLocaleString('fr-FR', { style: 'decimal' })} XOF</span></td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
              {
                connected && !orderInfo?.paid &&
                <Button className="btn btn-default update" onClick={() => navigate(`/Order-product/${orderRef}`)}>Passez au paiement</Button>
              }
            </table>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  )
}

export default Checkout

