

class LocalStorage {
    static localStorageInstance: LocalStorage;

    private constructor() { };

    public static getInstance() {
        if (!LocalStorage.localStorageInstance) {
            LocalStorage.localStorageInstance = new LocalStorage();
        }
        return LocalStorage.localStorageInstance;
    }

    async set(key: string, value: string) {
        try {
            await localStorage.setItem(key, value);
        } catch (error) {

        }
    }

    async get(key: string) {
        try {
            const item = await localStorage.getItem(key);
            return item;
        } catch (error) {
            return '';
        }
    }

    async remove(key: string) {
        try {
            await localStorage.removeItem(key)
            // console.warn("Remove data from async storage", key);
        } catch (error) {

        }
    }
}

export default LocalStorage.getInstance();